.started-section {
    margin-bottom: 8rem;
    background-color: #F9F8F8;
    padding-top: 6rem;
    padding-bottom: 4rem;
  .started-container {
    width: 85%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .heading {
      font-family: Poppins;
      font-size: 40px;
      font-weight: 600;
      line-height: 64px;
      letter-spacing: -0.02em;
      text-align: center;
      color: #261e1e;
    }
    .content {
        padding-top: 2rem;
      .ant-row {
        .ant-col {
          .item {
            position: relative;
            img {
              opacity: 0.9;
              padding-left: 2rem;
            }
            .box {
              position: relative;
              bottom: 5rem;
              border: 1px solid #e1e1e1;
              border-radius: 10px;
              padding: 2rem;
              .box-item {

                .title {
                  font-size: 20px;
                  font-weight: 600;
                  line-height: 30px;
                  letter-spacing: 0em;
                  text-align: left;
                  color: #251f17;
                }
                .sub-title {
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 24px;
                  letter-spacing: 0em;
                  text-align: left;
                  color: #68625c;
                }
              }
            }
          }
        }
      }
    }
    .primaryBtn{
        border: none;
        outline: none;
        width: 191.6px;
        height: 50px;
        background-color: #251F17;
        color: #FFFFFF;
font-size: 14px;
font-weight: 600;
line-height: 21px;
letter-spacing: 0em;
text-align: center;


      }
  }
}
