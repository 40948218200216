:root {
  --font-size-h1: 54px;
  --font-size-h2: 20px;
  --font-size-h3: 24px;
  --font-size-title: 30px;
  --font-size-h4: 32px;
  --font-size-paragraph: 28px;
  --font-size-text: 18px;

}

@media screen and (max-width: 600px) {
  :root {
    --font-size-h1: 38px;
    --font-size-h2: 24px;
    --font-size-h3: 28px;
    --font-size-title: 25px;
    --font-size-paragraph: 24px;
  }
}

body {
  background: #fff;
}

.guides_main_container {
  width: 100%;
  height: 100%;
  background: #fff;
  // background: #EEBABB;
  // backdrop-filter: blur(749.6199340820312px);


  .header_wrapper {
    width: 100%;
    height: 200px;

    .header_content {
      padding: 40px 60px;
      display: flex;
      justify-content: space-between;



      .profile_wrapper {
        display: flex;
        gap: 20px;
        align-items: flex-end;

        .profile_details_wrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          .profile_name_wrapper {
            display: flex;
            gap: 10px;
            align-items: center;

            span {
              font-size: 32px;
              color: #ff5858;
              font-weight: 700;
            }
          }

          .designation {
            font-size: 20px;
            font-weight: 400;
          }
        }
      }
    }
  }

  .guides_first_fold_wrapper {
    // margin-top: 10rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    background: url('../../assets/images/guides/background.png');

    .guides_left {
      padding-top: 100px;
      padding-left: 30px;

      .logo_wrapper {
        width: 58px;
        margin-bottom: 32px;


        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          cursor: pointer;
        }
      }

      .content {
        width: 100%;
        height: 100%;

        h1 {
          font-size: var(--font-size-h1);
          font-weight: 400;
          line-height: 1.2;

          span {
            color: #ff5858;
            font-weight: 700;
          }
        }

        h2 {
          font-size: var(--font-size-h2);
          font-weight: 400;
          line-height: 1.5;
          margin-top: 15px;
        }

        button {
          box-shadow: -227px 126px 73px 0px #00000000;
          width: 248px;
          height: 51px;
          border: none;
          color: #ff5858;
          font-size: var(--font-size-text);
          font-weight: 600;
          border: 1px solid #FF5858;
          border-radius: 55px;
          background-color: transparent;
          margin-top: 30px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-left: 30px;

          .arrow_icon {
            background: #ff5858;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    .guides_right {
      width: 100%;
      text-align: right;
    }
  }

  .guides_second_fold_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .content {
      width: 75%;

      .image_wrapper {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
        }
      }

      .description {
        font-size: var(--font-size-h3);
        font-weight: 400;
        color: #000;
        line-height: 1.5;
        text-align: center;

        span {
          display: block;
        }
      }
    }
  }

  .third_fold_main_wrapper {
    margin-top: 6.25rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .heading {
        width: 100%;
        text-align: center;

        h1 {
          font-size: var(--font-size-h4);
          font-weight: 600;
          line-height: 1.5;
          color: #151b31;
          margin-bottom: 15px;

          span {
            color: #ff5858;
          }
        }

        h2 {
          color: #000;
          font-size: var(--font-size-h3);
          font-weight: 400;
          line-height: 1.5;
        }
      }

      .arrow_image_section {
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: space-around;
      }

      .arrow_image_section2 {
        // margin-top: 10px;
        width: 85%;
        display: flex;
        justify-content: space-around;
        position: relative;
        bottom: 10px;
      }

      .image_section_wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 6rem;
        margin-top: 10px;

        .card {
          .title {
            margin-top: 10px;
            font-size: var(--font-size-h2);
            font-weight: 400;
            line-height: 1.5;
            text-align: center;
            color: #000;
          }
        }
      }

      .image_section_wrapper2 {
        width: 65%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 6rem;
        margin-top: 10px;
        justify-content: space-around;

        .card {
          .title {
            font-size: var(--font-size-h2);
            font-weight: 400;
            line-height: 1.5;
            text-align: center;
            color: #000;
          }
        }
      }
    }
  }

  .sixth_fold_container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // align-items: center;
    margin-top: 60px;

    .sixth_fold_content {
      width: 60%;
      height: 100%;
      padding-left: 30px;
      // text-align: center;

      h1 {
        font-size: 50px;
        font-weight: 400;
        color: #151b31;
        line-height: 1.5;
        width: 70%;

        span {
          color: #ff5858;
          font-weight: 600;
        }
      }

      h2 {
        font-size: var(--font-size-h3);
        font-weight: 400;
        color: #000;
        line-height: 1.5;
        margin-top: 10px;
        width: 75%;
      }

      button {
        box-shadow: -227px 126px 73px 0px #00000000;
        width: 600px;
        height: 130px;
        border: none;
        color: #ff5858;
        font-size: var(--font-size-h1);
        font-weight: 700;
        border-radius: 26px;
        background-color: #ffffff;
        margin: 10rem 0px;
        cursor: pointer;
      }
      .linkedin_box{
        background: #0B66C3;
        padding:10px 20px;
        border-radius: 6px;
        width: 36%;
        margin-top: 30px;
        cursor: pointer;
        h2{
          color:#ffffff;
          font-size: 16px;
          font-weight: 600;
          margin-top: 0;
          display: flex;
          align-items: center;
          gap:10px;
        }
        a{
          color:#ffffff;
          text-decoration: none;
          font-size: 12px;
        }
      }
    }

    .form_wrapper {
      background: #FBFBFB;
      padding: 30px;
      width: 40%;

      form {
        .field {
          width: 100%;
          display: flex;
          flex-direction: column;

          label {
            font-size: 16px;
            font-weight: 400;
            color: #1E1E1E;
          }

          input[type="text"] {
            margin-top: 6px;
            border: 1px solid #D2D2D2;
            padding: 16px 13px;
            border-radius: 6px;
            margin-bottom: 16px;
            background: transparent;

          }

          input[type="search"] {
            padding: 16px 13px;
          }

          .ant-input-number-group-wrapper {
            margin-bottom: 16px;
            margin-top: 6px;

            .ant-input-number-wrapper {
              .ant-input-number {
                padding: 10px 13px;
              }
            }
          }
        }

        .download_btn {
          background: #FF5858;
          width: 100%;
          height: 57px;
          border: none;
          border-radius: 6px;
          color: #ffffff;
          font-size: var(--font-size-h2);
          cursor: pointer;
        }
      }
    }
  }

  // .common_wrapper{
  //   background: #FFF8F8;
  // }

  .fifth_fold_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFF8F8;
    padding-bottom: 90px;

    .fifth_fold_content {
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4rem;

      .heading {
        width: 100%;
        text-align: center;

        h1 {
          font-size: var(--font-size-h4);
          font-weight: 600;
          color: #151b31;
          line-height: 1.5;

          span {
            color: #ff5858;
          }
        }
      }

      .cards_container1 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2.5rem;

        .card {
          background-color: #ffffff;
          border-radius: 8px;
          box-shadow: 1px 1px 3px 0px #FFA3A30D, 3px 4px 5px 0px #FFA3A30A, 6px 10px 7px 0px #FFA3A308, 11px 17px 8px 0px #FFA3A303, 17px 26px 9px 0px #FFA3A300;
          width: 100%;
          border-left: 6px solid #FFCDCD;
          border-radius: 6px 0 0 6px;

          .card_content {
            // width: 60%;
            padding: 20px 30px 20px 30px;



            h2 {
              font-size: var(--font-size-h2);
              font-weight: 700;
              line-height: 1.3;
            }

            h4 {
              margin-top: 12px;
              font-size: 18px;
              font-weight: 400;
              width: 80%;
            }
          }
        }
      }

      .cards_container2 {
        display: grid;
        width: 65%;
        grid-template-columns: repeat(2, 1fr);
        justify-content: space-around;
        gap: 2.5rem;

        .card {
          background-color: #ffffff;
          border-radius: 8px;
          box-shadow: 1px 1px 3px 0px #FFA3A30D, 3px 4px 5px 0px #FFA3A30A, 6px 10px 7px 0px #FFA3A308, 11px 17px 8px 0px #FFA3A303, 17px 26px 9px 0px #FFA3A300;
          width: 100%;
          border-left: 6px solid #FFCDCD;
          border-radius: 6px 0 0 6px;

          .card_content {
            // width: 60%;
            padding: 20px 30px 20px 30px;

            h2 {
              font-size: var(--font-size-h2);
              font-weight: 700;
              line-height: 1.5;
            }

            h4 {
              margin-top: 12px;
              font-size: 20px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }

  .forth_fold_container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    background: linear-gradient(to bottom, #ffffff 60%, #FFF8F8 40%);
    padding-bottom: 100px;

    .forth_fold_content {
      width: 85%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 60px;

      .heading {
        width: 100%;
        text-align: center;

        h1 {
          font-size: var(--font-size-h4);
          font-weight: 600;
          color: #151b31;
          line-height: 1.5;

          span {
            color: #ff5858;
          }
        }
      }

      .slider_cards_container {
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        // scroll-behavior: smooth;
        justify-content: center;

        .card_content {
          flex: 0 0 100%;
          height: 100%;
          display: flex;
          // border: 1px solid #0000002b;
          border-radius: 16px;
          background: #FF5858;
          // padding: 60px;
          // transition: transform 0.5s ease-in-out;
          justify-content: space-between;
          position: relative;

          .left_wrapper {
            width: 55%;
            padding-top: 46px;
            color: #fff;
            z-index: 9;
            position: relative;

            .quote_icon {
              position: absolute;
              top: 35px;
              z-index: 1;
              width: 113px;
              height: 67px;
              left: -18px;
            }

            .title {
              font-size: var(--font-size-h3);
              font-weight: 700;
              padding-right: 49px;
              padding-top: 24px;
              position: relative;
              z-index: 9;


              span {
                color: #ff5858;
              }
            }

            .description {
              margin: 20px 49px 0 0;
              font-size: var(--font-size-h2);
              font-weight: 400;
            }


          }

          .right_wrapper {
            width: 40%;
            position: relative;
            // aspect-ratio: 1.06;

            // right: -40px;
            img {
              width: 100%;
              // transform: rotate(9deg);
            }

          }

          .user {
            font-size: 18px;
            font-weight: 400;
            position: absolute;
            bottom: 65px;
            left: 230px;
            background: #fff;
            box-shadow: 2px 1px 6px 0px #0000000A, 10px 5px 11px 0px #00000008, 22px 12px 15px 0px #00000005, 40px 21px 18px 0px #00000003, 62px 33px 20px 0px #00000000;
            padding: 10px 20px;
            &.mobile{
              display: none;
            }

            span {
              font-weight: 700;
              color: #FF5858;
            }
          }
        }
      }
    }
  }
}

.download_modal_main_container {
  width: 100%;
  height: 100%;
  padding: 60px;

  form {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    .form_control {
      display: flex;
      flex-direction: column;
      gap: 10px;

      label {
        font-size: 16px;

        sup {
          color: red;
        }
      }

      input {
        padding: 10px;
        height: 40px;
        font-size: 16px;
      }

      select {
        width: 100px;
      }

      button {
        width: 200px;
        height: 40px;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width:1400px) {
  .guides_main_container .forth_fold_container .forth_fold_content .slider_cards_container .card_content {
    align-items: center;
  }
  .slider_cards_container {
    .card_content {
      .user{
        display: block;
        z-index:9;
        &.mobile{
          display: none;
        }
      }
    }
}
.sixth_fold_content{
  .linkedin_box{
    width: 40%;
  }
}
}


@media screen and (max-width: 992px) {

  .guides_main_container .sixth_fold_container .form_wrapper form .field .ant-input-number-group-wrapper{
    margin-bottom: 30px;
  }

  .guides_main_container {
    .header_wrapper {
      height: 100px;

      .header_content {
        padding: 20px;

        .logo_wrapper {
          width: 60px;
          height: 60px;
        }

        .profile_wrapper {
          .profile_image_wrapper {
            width: 40px;
            height: 40px;
            order: +1;

            img {
              width: 100%;
            }
          }

          .profile_details_wrapper {
            .profile_name_wrapper span {
              font-size: 16px;
            }

            .designation {
              font-size: 12px;
            }
          }
        }

        .break {
          display: none;
        }
      }
    }

    .third_fold_main_wrapper .content {

      .arrow_image_section,
      .arrow_image_section2 {
        display: none;
      }

      .image_section_wrapper {
        margin-top: 50px;
        grid-template-columns: repeat(1, 1fr);

        .card {
          .image_wrapper {
            img {
              width: 100%;
            }
          }
        }
      }

      .image_section_wrapper2 {
        margin-top: 50px;
        grid-template-columns: repeat(1, 1fr);
        width: 100%;

        .card {
          .image_wrapper {
            img {
              width: 100%;
            }
          }
        }
      }
    }

    .forth_fold_container .forth_fold_content {
      .heading {
        width: 90%;
      }

      .slider_cards_container {
        .card_content {
          flex-direction: column;
          padding: 0;
          border-radius: 18px 109px 18px 18px;
          .user{
           display: none;
           &.mobile{
            display: block;
            left:unset;
            right:0;
            width: 50%;
           }
          }
          .left_wrapper{
            padding:0 30px 30px;
            .quote_icon{
              top:15px;
              left:22px;
            }
          }
          .left_wrapper,
          .right_wrapper {
            width: 100%;
          }

          .right_wrapper {
            // transform: rotate(0deg);
            right: 0px;
            margin-top: 0;
          }
        }
      }
    }

    .fifth_fold_wrapper .fifth_fold_content {
      .heading {
        width: 100%;
      }

      .cards_container1 {
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
      }

      .cards_container2 {
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
        gap: 5rem;
      }
    }

    .sixth_fold_container{
      flex-direction: column;
    }
    .sixth_fold_container .sixth_fold_content button {
      width: 100%;
      height: 80px;
      font-size: 24px;
    }

    .sixth_fold_container .sixth_fold_content{
      width: 100%;
      text-align: center;
      h1,h2{
        width: 100%;
      }
      .linkedin_box{
        margin:20px auto;
        width: 60%;
      }
    }
    .sixth_fold_container .form_wrapper{
      width:100%;
    }
   
    .guides_first_fold_wrapper {
      flex-direction: column;
        padding: 20px;

      .content {
        width: 90%;

        button {
          width: 100%;
          height: 80px;
          font-size: 24px;
        }
      }
      .guides_right{
        width: 60%;
        margin:0 auto;
        img{
          width: 100%;
        }
      }
    }

    .guides_second_fold_wrapper {
      margin-top: 5rem;
    }
  }

  .download_modal_main_container {
    padding: 60px 20px;

    form {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
