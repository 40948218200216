.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  z-index: 2;
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger.is-active:hover {
  opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #000;
}

.hamburger-box {
  width: 25px;
  height: 1.95px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 25px;
  height: 1.95px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
   
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -10px;
}
.hamburger-inner::after {
  bottom: -10px;
}


.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}




.text-focus-in {
	-webkit-animation: text-focus-in 0.4s ease-in 0.1s both;
	        animation: text-focus-in 0.4s ease-in 0.1s both;
}
 @-webkit-keyframes text-focus-in {
        0% {
          -webkit-filter: blur(12px);
                  filter: blur(12px);
          opacity: 0;
        }
        100% {
          -webkit-filter: blur(0px);
                  filter: blur(0px);
          opacity: 1;
        }
      }
      @keyframes text-focus-in {
        0% {
          -webkit-filter: blur(12px);
                  filter: blur(12px);
          opacity: 0;
        }
        100% {
          -webkit-filter: blur(0px);
                  filter: blur(0px);
          opacity: 1;
        }
      }






      .scale-in-bl {
        -webkit-animation: scale-in-bl 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: scale-in-bl 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      }
      
      @-webkit-keyframes scale-in-bl {
        0% {
          -webkit-transform: scale(0);
          transform: scale(0);
          -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
          opacity: 1;
        }
        100% {
          -webkit-transform: scale(1);
          transform: scale(1);
          -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
          opacity: 1;
        }
      }
      @keyframes scale-in-bl {
        0% {
          -webkit-transform: scale(0);
          transform: scale(0);
          -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
          opacity: 1;
        }
        100% {
          -webkit-transform: scale(1);
          transform: scale(1);
          -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
          opacity: 1;
        }
      }