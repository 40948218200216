@import "../../assets/scss/mixin.scss";
.getting-quality-section {
  padding: 4rem 0;
  .getting-quality-container {
    background-color: #fffbfa;
    padding: 5rem 0;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    .top-section {
      .sub-heading {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: center;
        color: #0d0d0d;
      }
      .heading {
        font-size: 38px;
        font-weight: 500;
        line-height: 57px;
        letter-spacing: 0em;
        text-align: center;
        color: #0d0d0d;
      }
      .text {
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: center;
        color: #0d0d0d;
      }
    }
    .bottom-section {
      width: 85%;
      margin: 0 auto;
      .cards {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .card {
          width: 30%;
          height: 309px;
          border-radius: 19px;
          background-color: #ffffff;
          box-shadow: 4px 4px 12px 0px #0c5f9b14;
          padding: 3rem 2.2rem;
          .card-title {
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
            letter-spacing: 0em;
            text-align: left;
            color: #000000;
          }
          .card-text {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: #595959;
          }
        }
      }
    }
  }
}
@include breakpoint(mobile) {
  .getting-quality-section {
    padding: 4rem 0;
    .getting-quality-container {
      background-color: #fffbfa;
      padding: 5rem 0;
      display: flex;
      flex-direction: column;
      gap: 3rem;
      .top-section {
        width: 90%;
        margin: 0 auto;
        .sub-heading {
          font-family: Poppins;
          font-size: 10px;
          font-weight: 500;
          line-height: 15px;
          letter-spacing: 0em;
          text-align: center;

          color: #0d0d0d;
        }
        .heading {
          font-family: Poppins;
          font-size: 20px;
          font-weight: 500;
          line-height: 31px;
          letter-spacing: 0em;
          text-align: center;

          color: #0d0d0d;
        }
        .text {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: center;
          color: #0d0d0d;
        }
      }
      .bottom-section {
        width: 85%;
        margin: 0 auto;
        .cards {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .card {
            width: 90%;
            height: 204px;
            border-radius: 19px;
            background-color: #ffffff;
            box-shadow: 4px 4px 12px 0px #0c5f9b14;
            padding: 1rem 1rem;
            .card-title {
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
              letter-spacing: 0em;
              text-align: left;

              color: #000000;
            }
            .card-text {
              font-family: Poppins;
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
              letter-spacing: 0em;
              text-align: left;

              color: #595959;
            }
          }
        }
      }
    }
  }
}
