.world-wide-section {
  background: url("../../assets/images/carousel-bg.webp");
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 8rem;
  .world-wide-container {
    width: 85%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .top-section {
      .heading {
        font-family: Poppins;
        font-size: 40px;
        font-weight: 500;
        line-height: 60px;
        letter-spacing: 0em;
        text-align: center;
        color: #000000;

        span {
          color: #de6b32;
        }
      }
    }
    .middle-section {
      .ant-row {
        .ant-col {
         
          .button-container {
            width: 100%;
            height: 282px;
            background: url("../../assets/images/rectangle.webp");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: inherit;
            align-self: flex-end;
            .button-section{
              height: 100%;
               display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: flex-end;
              padding-bottom: 2.5rem;
              .text {
                // font-family: Inter;
                font-size: 24px;
                font-weight: 500;
                line-height: 60px;
                letter-spacing: 0em;
                text-align: center;
                color: #f5f5f5;
                white-space: nowrap;
              }
              .view-more-btn {
                border: none;
                outline: none;
                border-radius: 12px;
                width: 200px;
                height: 50px;
                background-color: #ca6128;
                font-size: 20px;
                font-weight: 500;
                line-height: 30px;
                letter-spacing: 0em;
                text-align: center;
                color: #f5f5f5;
              }
            }
          }
          .col-1 {
            height: 500px;
            background: url("../../assets/images/Poland.webp");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 10.59px;
            display: flex;
          }

          .col-2 {
            background: url("../../assets/images/new-york.webp");
          }
          .col-3 {
            background: url("../../assets/images/los.webp");
          }
          .col-4 {
            background: url("../../assets/images/itely.webp");
          }
          .col-5 {
            background: url("../../assets/images/barlin.webp");
          }
          .col-6 {
            background: url("../../assets/images/Bulgaria.webp");
          }
          .col-7 {
            background: url("../../assets/images/manila.webp");
          }
          .col-8 {
            background: url("../../assets/images/Bengaluru.webp");
          }
          .col-9 {
            background: url("../../assets/images/newDelhi.webp");
          }
          .col-10 {
            background: url("../../assets/images/beijing.webp");
          }
          .col-11 {
            background: url("../../assets/images/hong-kong.webp");
          }
          .col-12 {
            background: url("../../assets/images/bangkok.webp");
          }
          .col-13 {
            background: url("../../assets/images/dhaka.webp");
          }
          .col-14 {
            background: url("../../assets/images/tirupur.webp");
          }
          .col-15 {
            background: url("../../assets/images/ho-chi.webp");
          }
          .col-16 {
            background: url("../../assets/images/istanbul.webp");
          }
          .col-17 {
            background: url("../../assets/images/porto.webp");
          }
          .col {
            height: 500px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 10.59px;
            display: flex;
            .button-container{
              align-self: flex-end;
              height: 282px;
              .button-section{
                height: 100%;
                display: flex;
                flex-direction: row;
                padding: 0 2.5rem;
                align-items: flex-end;
                padding-bottom: 2.5rem;
                justify-content: space-between;
              }
            }
          }
        }
      }
    }
    .bottom-section {
      padding-top: 3rem;
      .cards {
        width: 80%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .card {
          width: 302.98px;
          height: 231.14px;
          background-color: #f5f5f5;
          border-radius: 26.85px;
          display: flex;
          align-items: center;
          justify-content: center;
          .card-item {
            .bold {
              font-size: 40px;
              font-weight: 700;
              line-height: 60px;
              letter-spacing: 0em;
              text-align: center;
              color: #000000;
            }
            .text {
              font-size: 19px;
              font-weight: 400;
              line-height: 29px;
              letter-spacing: 0em;
              text-align: center;
              color: #000000;
            }
          }
        }
      }
    }
  }
}
