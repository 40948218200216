@import "../../assets/scss/variable.scss";

.good-fit-section {
  margin-bottom: 8rem;
  .good-fit-container {
    width: 85%;
    margin: 0 auto;
    text-align: center;
    .title-heading {
      font-size: 40px;
      font-weight: 600;
      line-height: 64px;
      letter-spacing: -0.02em;
      color: #261e1e;
      // margin: 0;
    }
    .title-text {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      color: #544e47;
    }
    .img-container {
      .ant-row {
        display: flex;
        justify-content: space-between;
        margin-top: 3rem;
        .col {
          height: 288px;
          font-size: 24px;
          font-weight: 600;
          line-height: 36px;
          letter-spacing: 0em;
          text-align: center;
          color: $white;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6.03px;
          cursor: pointer;
        }

        .col-1 {
          background: url("../../assets/images/img_1.webp");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          transition: ease-out all 300ms;

          &:hover {
            background: url("../../assets/images/hover_img_1.webp");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
        .col-2 {
          background: url("../../assets/images/img_2.webp");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          transition: ease-out all 300ms;

          &:hover {
            background: url("../../assets/images/hover_img_2.webp");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
        .col-3 {
          background: url("../../assets/images/img_3.webp");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          transition: ease-out all 300ms;

          &:hover {
            background: url("../../assets/images/hover_img_3.webp");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
        .col-4 {
          background: url("../../assets/images/img_4.webp");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          transition: ease-out all 300ms;

          &:hover {
            background: url("../../assets/images/hover_img_4.webp");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
        .col-5 {
          background: url("../../assets/images/img_5.webp");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          transition: ease-out all 300ms;

          &:hover {
            background: url("../../assets/images/hover_img_5.webp");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
        .col-6 {
          background: url("../../assets/images/img_6.webp");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          transition: ease-out all 300ms;

          &:hover {
            background: url("../../assets/images/hover_img_6.webp");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
        .col-7 {
          background: url("../../assets/images/img_7.webp");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          transition: ease-out all 300ms;

          &:hover {
            background: url("../../assets/images/hover_img_7.webp");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
        .col-8 {
          background: url("../../assets/images/img_8.webp");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          transition: ease-out all 300ms;

          &:hover {
            background: url("../../assets/images/hover_img_8.webp");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
        .col-9 {
          background: url("../../assets/images/img_9.webp");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          transition: ease-out all 300ms;

          &:hover {
            background: url("../../assets/images/hover_img_9.webp");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
}
