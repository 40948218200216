// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./assets/scss/variable.scss";
@import "react-alice-carousel/lib/scss/alice-carousel.scss";

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #ffd4bc !important;
  width: 1px !important;
}

::-webkit-scrollbar-thumb {
  width: 6.11px;
  height: 87px;
  background: $primary;
  border-radius: 3.05321px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.policy_page,
.terms_page {
  padding: 80px 300px 80px 100px;
  ul {
    margin-left: 24px;
  }
}
.terms_page {
  padding: 80px 100px;
  ul {
    line-height: 2rem;
  }
}
@media screen and (max-width: 992px) {
  .policy_page,
  .terms_page {
    padding: 45px;
    margin-top: 95px;
    p {
      font-size: 1rem;
    }
    ul {
      margin-left: 24px;
      li {
        font-size: 1rem;
      }
    }
  }
}
.para_gap {
  line-height: 1.65rem;
  margin-bottom: 1.45rem;
}
.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-3 {
  margin-top: 3rem;
}
.mt-4 {
  margin-top: 4rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-3 {
  margin-bottom: 3rem;
}
.mb-4 {
  margin-bottom: 4rem;
}

@media all and (max-width: 1279px) {
  .max-width {
    max-width: 80%;
  }
}

@media all and (max-width: 600px) {
  .max-width {
    max-width: 90%;
  }
  .non-mobile {
    display: none !important;
  }
}

@media all and (min-width: 600px) {
  .only-mobile {
    display: none !important;
  }
}
