@import "../../assets/scss/variable.scss";
@import "../../assets/scss/mixin.scss";

.account-manager-section {
  .account-manager-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: url("../../assets/images/rectangle-3.webp");
    background-size: auto;
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: bottom;
    .left {
      width: 100%;
    }
    .right {
      width: 75%;
      padding-top: 5rem;
      .heading {
        font-size: 26px;
        font-weight: 500;
        line-height: 39px;
        letter-spacing: 0em;
        text-align: left;
        color: $blackb2;
        span {
          font-weight: 700;
          color: $primary;
        }
      }
      .carousel {
        position: relative;
        padding-top: 1rem;
        .alice-carousel {
          
          .alice-carousel__wrapper {
            .alice-carousel__stage {
              
              .alice-carousel__stage-item {
                // background-color: black;
                width: 321px !important;
                height: 223px !important;
                border-radius: 12px;
                margin-right: 2rem;
                background-color: $white;
                box-shadow: 0px 16px 120px 0px #161e2414;
                padding: 1rem 1.5rem;
                .items {
                  display: flex;
                  flex-direction: column;
                  gap: 1rem;
                  .num {
                    font-size: 46px;
                    font-weight: 600;
                    line-height: 55px;
                    letter-spacing: -0.06em;
                    text-align: left;
                    color: $greyg6;
                  }
                  .text {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: $blackb5;
                  }
                  .title {
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 30px;
                    letter-spacing: 0em;
                    text-align: left;
                  }
                }
              }
            }
          }
         
        }
      }
    }
  }
}

@include breakpoint(tablet){
  .account-manager-section {
    .account-manager-container {
  
      .left {
        width: 40%;
      }
      .right {
        width: 60%;
        padding-top: 5rem;
        .heading {
          font-size: 20px;
          font-weight: 500;
          line-height: 30.1px;
         
        
        }
        .carousel {
          position: relative;
          padding-top: 1rem;
          .alice-carousel {
            .alice-carousel__wrapper {
              .alice-carousel__stage {
                .alice-carousel__stage-item {
                  // background-color: black;
                  width: 201.65px !important;
                  height: 155.6px !important;
                  border-radius: 8.37px;
                  margin-right: 2rem;
                  background-color: $white;
                  box-shadow: 0px 11.163987159729004px 83.72989654541016px 0px #161E2414;
                  padding: 1rem 1.5rem;
                  .items {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    .num {
                      font-size: 32.21px;
                      font-weight: 600;
                      line-height: 38.17px;
                      
                    }
                    .text {
                      font-size: 11.16px;
                      font-weight: 400;
                      line-height: 16.8px;
                      
                    }
                    .title {
                      font-size: 13.95px;
                      font-weight: 500;
                      line-height: 21px;
                    }
                  }
                }
              }
            }
            // .alice-carousel__prev-btn {
            //   position: absolute;
            //   width: 24px;
            //   padding: 0;
            //   top: 16rem;
            //   img {
            //     width: 100%;
            //   }
            // }
            // .alice-carousel__next-btn {
            //   position: absolute;
            //   width: 24px;
            //   padding: 0;
            //   top: 16rem;
            //   margin-left: 3rem;
            //   img {
            //     width: 100%;
            //   }
            // }
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .account-manager-mobile-section {
    padding-bottom: 4rem;
    .account-manager-container {
      // display: flex;
      // flex-direction: column;
      background-color: #fff1d7;
      padding: 1rem;
      .heading {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        padding-bottom: 1rem;
        color: $blackb2;
        span {
          font-weight: 600;
          color: $primary;
        }
      }
      .carousel {
        padding-bottom: 2rem;
        position: relative;
        .alice-carousel {
          
          .alice-carousel__wrapper {
            padding-bottom: 1rem;
            padding-right: 100px;
            .alice-carousel__stage {
              .alice-carousel__stage-item {
                width: 259.95px !important;
                height: 171px !important;
                margin-right: 1rem;
                border-radius: 9px;
                background-color: $white;
                box-shadow: 0px 16px 120px 0px #161e2414;
                padding: 1rem 1.5rem;
                .items {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 0.5rem;
                  .num {
                    font-size: 35px;
                    font-weight: 600;
                    line-height: 41px;
                    letter-spacing: -0.06em;
                    text-align: center;

                    color: $greyg6;
                  }
                  .text {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0em;
                    text-align: left;

                    color: $blackb5;
                  }
                  .title {
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 23px;
                    letter-spacing: 0em;
                    text-align: left;
                  }
                }
              }
            }
            
          }
          .alice-carousel__prev-btn {
          
            position: absolute;
            width: 24px;
            padding: 0;
            // top: 16rem;
            img {
              width: 100%;
            }
          }
          .alice-carousel__next-btn {
            position: absolute;
            width: 24px;
            padding: 0;
            // top: 16rem;
            margin-left: 3rem;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 370px){
  
  .account-manager-mobile-section {
    .account-manager-container {
      
      .heading {
        font-size: 16px;
        
       
      }
    
    }
  }

}
