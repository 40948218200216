@import "../../assets/scss/mixin.scss";

.story-section {
  padding: 4rem 0;
.story-container {
  width: 85%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  background-color: #FFFAEF;
  border-radius: 20px;
      
      .left-side{
          width: 40%;
      }
  .right-side {
      padding: 5rem;
      width: 60%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    .sub-heading {
font-size: 21px;
font-weight: 500;
line-height: 32px;
letter-spacing: 0em;
text-align: left;
color: #DE6B32;
    }
    .heading {
      font-size: 30px;
      font-weight: 500;
      line-height: 45px;
      letter-spacing: 0em;
      text-align: left;
      color: #252628;
    }
    .text {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      color: #544e47;
    }
    .readmoreBtn {
      border: none;
      outline: none;
      width: 122px;
      height: 42px;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0em;
      color: #001b49;
      border: 1.5px solid #001b49;
      background-color: transparent;
    }
  }
}
}


@include breakpoint(mobile){
  .story-section {
    padding: 4rem 0;
  .story-container {
    width: 85%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFFAEF;
    border-radius: 20px;   
        .left-side{
            width: 100%;
        }

    .right-side {
        padding: 2rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      .sub-heading {
    
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        
color: #DE6B32;
      }
      .heading {
      
font-size: 20px;
font-weight: 500;
line-height: 33px;
letter-spacing: 0em;
text-align: left;

        color: #252628;
      }
      .text {
   
font-size: 14px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0em;
text-align: left;

        color: #544e47;
      }
      .readmoreBtn {
        border: none;
        outline: none;
        width: 122px;
        height: 42px;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        color: #001b49;
        border: 1.5px solid #001b49;
        background-color: transparent;
      }
    }
  }
}

}