@import "../../assets/scss/variable.scss";
@import "../../assets/scss/mixin.scss";

.how-it-work-section {
  .work-container {
    position: relative;
    .top-section {
      padding-top: 4rem;
      .heading-section {
        width: 80%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 2rem;
        padding-bottom: 2rem;
        .left-side {
          width: 48%;
          .heading {
            font-family: Poppins;
            font-size: 36px;
            font-weight: 600;
            line-height: 48px;
            letter-spacing: 0em;
            text-align: left;
            color: $blackb3;
          }
          .text {
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            letter-spacing: 0em;
            text-align: left;
            color: $grey;
          }
        }
        .right-side {
          display: flex;
          .img-right {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .text {
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0em;
              color: $grey;
            }
            .heading {
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
              letter-spacing: 0em;
              text-align: left;
              color: $grey;
              span {
                font-weight: 400;
              }
            }
          }
        }
      }
    }

    .button-section {
      border-bottom: 1px solid $greyg3;
      background: #fff;
      position: sticky;
      top: 4.5rem;
      // z-index: 9;
      .button-wrapper {
        width: 80%;
        margin: 0 auto;

        ul {
          list-style: none;
          display: flex;
          flex-wrap: wrap;
          // gap: 1rem 5rem;
          align-items: center;
          justify-content: space-around;
        }

        li {
          font-size: 18px;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: center;
          color: $blackb1;
          position: relative;
        }

        ul li .link {
          display: block;
          text-decoration: none;
          padding: 0.7rem 0;
          // text-align: left;
          color: inherit;
        }
        .active {
          color: #eb5851;
        }

        ul li::before {
          content: "";
          width: 100%;
          height: 3px;
          background: #eb5851;
          border-radius: 10px;
          position: absolute;
          bottom: 0;
          left: 0;
          transform: scaleX(0);
          transition: transform 0.5s ease;
        }

        ul li.active::before {
          transform: scaleX(1);
        }
      }
    }
    .bottom-section {
      .screen-text {
        opacity: 0;
      }
      .text-visible {
        opacity: 1;
        transition: 0.75s;
      }
      .item-wrapper {
        width: 80%;
        margin: 0 auto;
        padding: 4rem 0rem;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .img1{
          width: 514px;
        }
        .img2{
          width: 482px;
        }
        .img3{
          width: 579px;
        }
        .img4{
          width: 556px;
        }
        .img5{
          width: 652px;
        }
        .left-side {
          
          img {
            width: 100%;
            height: 100%;
          }
        }
        .right-side {
          width: 26.5rem;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          .title {
            font-size: 28px;
            font-weight: 600;
            line-height: 60px;
            letter-spacing: 0em;
            text-align: left;
          }
          .title-text {
            font-size: 18px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0em;
          }
        }
      }
      .hqi-wrapper {
        .right-side {
          .title {
            color: #d3a929;
            display: flex;
            align-items: center;
            img{
              width: 66.58px;
            }
          }
        }
      }
    }
  }
  .work-tablet-container {
    display: none;
  }
}

@include breakpoint(tablet) {
  .how-it-work-section{
    .work-container {
      display: none;
    }
    .work-tablet-container {
      display: block;
      padding-top: 4rem;
      .top-section {
        width: 65%;
        margin: 0 auto;
        .heading {
          font-size: 24px;
          font-weight: 600;
          line-height: 33.6px;
          letter-spacing: 0em;
          text-align: center;
        }
        .text {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: center;

          color: $grey;
        }
      }
      .bottom-section {
        display: flex;
        flex-direction: column;
        padding: 2rem 0;
        gap: 2rem;
        .item-wrapper {
          width: 65%;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          .top-side {
            // width: px;
            .title {
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
              letter-spacing: 0em;
              text-align: center;
            }
            .title-text {
              font-family: Poppins;
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
              letter-spacing: 0em;
              text-align: center;
              padding: 0.5rem 0;
            }
          }
          .bottom-side {
            img {
              width: 100%;
            }
          }
        }
        .hqi-wrapper {
          .top-side {
            .title {
              color: #d3a929;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 30.59px;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .how-it-work-section{
    .work-tablet-container {
      display: none;
    }
    .work-mobile-container {
      padding-top: 4rem;
      .top-section {
        width: 80%;
        margin: 0 auto;
        .heading {
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: center;
        }
        .text {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: center;

          color: $grey;
        }
      }
      .bottom-section {
        display: flex;
        flex-direction: column;
        padding: 2rem 0;
        gap: 2rem;
        .item-wrapper {
          width: 80%;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          .top-side {
            width: 295px;
            .title {
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
              letter-spacing: 0em;
              text-align: center;
            }
            .title-text {
              font-family: Poppins;
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
              letter-spacing: 0em;
              text-align: center;
              padding: 0.5rem 0;
            }
          }
          .bottom-side {
            img {
              width: 100%;
            }
          }
        }
        .hqi-wrapper {
          .top-side {
            .title {
              color: #d3a929;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 30.59px;
              }
            }
          }
        }
      }
    }
  }
}
