@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variable.scss";

.ant-modal-content {
  border-radius: 0;
  padding: 0 !important;
  .modal_inner_content {
    display: flex;
    justify-content: space-between;
    .modal-gif {
      width: 40%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .model_img_form {
        border-radius: 8px;
        width: 100%;
        height: 100%;
      }
    }
    .resp_height {
      height: calc(100vh - 95px) !important;
      border-radius: 0 !important;
    }

    .form {
      width: 60%;
      padding: 4rem;
      .text-center {
        text-align: center;
      }
      .form_title {
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: center;
        color: #261e1e;
      }
      .form_description {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;
        color: #544e47;
      }
      .form_label {
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        color: #261e1e;
        span {
          color: #ed0404;
        }
      }

      .form_inputs {
        width: 100%;
        height: 40px;
      }
      .ant-input-wrapper,
      .ant-input-number-input-wrap,
      .ant-select-selection-search {
        width: 100%;
        height: 40px;
        input {
          width: 100%;
          height: 40px;
          height: 36.37px;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: left;
          color: #a0a3bd;
        }
      }
      .input_space {
        width: 100%;
        height: 80px;
        text-align: left;
      }

      .error_class {
        color: #ff3030;
        padding-left: 5px;
        font-size: 10px;
        // margin-top: 2px;
      }

      .form-btns {
        width: 100%;
        height: 40px;
      }
      .form-btn-1 {
        background-color: $primary;
        color: white;
      }
      .form-btn-1:hover {
        color: white !important;
        border-color: $primary !important;
      }
      .form-btn-2 {
        border-color: $primary;
        color: $primary;
      }

      .dismiss-btn {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        color: #7a7d9a;
        text-align: center;
        &:hover {
          cursor: pointer;
        }
      }

      .country_select,
      .ant-input-number-input {
        width: 100px;
        height: 40px !important;
        .ant-select-selector {
          height: 40px !important;
          align-items: center;
        }
      }
      .ant-select-selection-search-input {
        height: 40px !important;
      }
    }

    .thankyou_page {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: auto;
      gap: 20px;
    }
    .hubspot_form {
      width: 55%;
      // height: 100%;
      padding: 40px;
    }
  }

  .ant-modal-close {
    // display: none;
  }
  .ant-modal-footer {
    display: none;
  }
}

@media screen and (max-width: 2048px) and (min-width: 1700px) {
  .model_img_form {
    height: 66vh !important;
  }
  .resp_height {
    height: calc(100vh - 95px) !important;
    border-radius: 0 !important;
  }
}
@media screen and (max-width: 1400px) {
  .model_img_form {
    height: 90vh !important;
  }
  .resp_height {
    height: calc(100vh - 95px) !important;
    border-radius: 0 !important;
  }
  // .modal_inner_content .input_space{
  //     height: 80px;
  // }

  // .ant-input-wrapper, .ant-input-number-input-wrap{
  //     width: 100%;
  //     height: 30px !important ;
  //     input{
  //         width: 100%;
  //     height: 30px !important;
  //     }
  // }
  .form_inputs,
  .ant-input-number-input-wrap,
  .ant-input-number-input,
  .ant-input-number-group-addon,
  .form-btns {
    width: 100%;
    height: 35px !important;
  }
  .ant-modal {
    top: 32px !important;
  }
  .input_space {
    height: 75px !important;
  }
  .country_select,
  .ant-select-selection-search-input {
    width: 100px;
    height: 35px !important;
    .ant-select-selector {
      height: 35px !important;
      align-items: center;
    }
  }
}
@media screen and (max-width: 992px) {
  .model_img_form {
    display: none;
  }
  .ant-modal-content .modal_inner_content .hubspot_form {
    width: 100%;
  }
  .form {
    padding: 25px !important;
    width: 100% !important;
  }
  .form_description {
    font-size: 0.8rem;
  }
  .form_inputs,
  .ant-input-number-input-wrap,
  .ant-input-number-input,
  .ant-input-number-group-addon,
  .form-btns {
    width: 100%;
    height: 45px !important;
  }
  .country_select,
  .ant-select-selection-search-input {
    width: 100px;
    height: 40px !important;
    .ant-select-selector {
      height: 40px !important;
      align-items: center;
    }
  }
  .thankyou_page {
    padding: 50px;
  }
  .form-btns {
    margin-top: 0 !important;
  }
  .form-btn-1 {
    margin-top: 10px !important;
  }
  .modal-gif {
    display: none;
  }
}
