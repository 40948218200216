.ecommerce-product-section {
    height: 850px;
    // height: 100vh;
    padding-top: 2rem;

    .ecommerce-product-container {
        position: relative;

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 3rem;

            .heading {


                font-size: 40px;
                font-weight: 500;
                line-height: 60px;
                letter-spacing: 0em;
                text-align: center;
                color: #000000;

                span {
                    font-weight: 700;
                    color: #DE6B32;
                    display: block;
                }

            }

            .text {
                padding-top: 2rem;
                font-size: 18px;
                font-weight: 400;
                line-height: 27px;
                letter-spacing: 0em;
                text-align: center;
                color: #000000DE;

            }
        }

        .top-left {
            position: absolute;
            left: 0;

            .p1 {
                position: relative;
                top: 3rem;
                z-index: 1;
            }

            .p2 {
                position: relative;
                bottom: 8.5rem;
                left: -3rem;
            }
        }

        .top-right {
            position: absolute;
            right: 0;

            .p3 {
                margin-right: 1rem;
            }


        }

        .bottom {
            position: relative;
            top: 10rem;

            .bottom-left {
                position: absolute;
                // bottom: 0;
                left: 0;

                .p5 {
                    position: relative;
                    bottom: 3rem;
                }

                .bottom-left-img {
                    position: relative;
                    // left: 3rem;
                    bottom: 4rem;

                    .p6 {
                        position: relative;
                        bottom: 8rem;
                        left: 3rem;
                    }

                    .p7 {
                        position: relative;
                        z-index: 1;
                    }
                }

            }

            .bottom-right {
                position: absolute;
                // bottom: 0;
                right: 0;

                .p8 {
                    position: relative;
                    right: 2rem;
                    bottom: 6rem;
                }

                .bottom-right-img {
                    position: relative;
                    bottom: 8rem;

                    .p9 {
                        position: relative;
                        left: 3rem;
                        top: 3rem;
                    }

                    .p10 {
                        position: relative;
                        z-index: 1;
                    }
                }
            }
        }
    }
}