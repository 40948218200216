.work-section{
    
    .work-container{
        background-color: #FEEDD5;
        padding: 4rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        .heading{
font-size: 38px;
font-weight: 500;
line-height: 57px;
letter-spacing: 0em;
text-align: center;
color: #000000;
        }
        .text{
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: center;

color: #000000;
        }
        .findJobBtn{
            border: none;
outline: none;
width: 254px;
height: 58px;

border: 1.68px solid #282626;
font-size: 17px;
font-weight: 600;
line-height: 25px;
letter-spacing: 0em;
text-align: center;
color: #282626;
display: flex;
align-items: center;
justify-content: center;
gap: 0.8rem;
background-color: transparent;
&:hover{
    border-color: #282626;
    color: #282626;
}
        }
    }
}