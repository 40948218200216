@import "../../assets/scss/mixin.scss";

.values-section {
  padding: 4rem 0;
  .values-container {
    width: 85%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    .top-section {
      .sub-heading {
        font-size: 21px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: #de6b32;
      }
      .heading {
        font-size: 38px;
        font-weight: 500;
        line-height: 57px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;
      }
    }
    .bottom-section {
      .cards {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .card {
          width: 32%;
          padding: 2rem 3rem;
          background-color: #fafafa;
          border-radius: 10px;
          box-shadow: 4.211826801300049px 4.211826801300049px
            12.635480880737305px 0px #0c5f9b14;
          .title {
            font-size: 22px;
            font-weight: 600;
            line-height: 33px;
            letter-spacing: 0em;
            text-align: left;
            color: #1e1e1e;
          }
          .text {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: #1e1e1e;
          }
        }
        .card1 {
          padding: 2rem 2.5rem;
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .values-section {
    padding: 4rem 0;
    .values-container {
      width: 85%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .top-section {
        .sub-heading {
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: center;

          color: #de6b32;
        }
        .heading {
          font-size: 20px;
          font-weight: 500;
          line-height: 31px;
          letter-spacing: 0em;
          text-align: center;

          color: #000000;
        }
      }
      .bottom-section {
        .cards {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          gap: 1rem;

          .card {
            width: 100%;
            padding: 1rem 2rem;
            background-color: #fafafa;
            border-radius: 8px;
           
            .title {

font-size: 16px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0em;
text-align: left;

              color: #1e1e1e;
            }
            .text {
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: left;

              color: #1e1e1e;
            }
          }
          .card1 {
            padding: 1rem 2rem;
          }
        }
      }
    }
  }
}
