@import "../../assets/scss/mixin.scss";

.our-teams-section {
  padding: 4rem 0;
  .teams-container {
    width: 85%;
    margin: 0 auto;
    .heading {
      background: url("../../assets/images/ourTeam.webp");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      font-size: 38px;
      font-weight: 500;
      line-height: 57px;
      letter-spacing: 0em;
      text-align: center;
      color: #000000;
      margin-bottom: 1rem;
    }
    // .cards-section {
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    //   .card {
    //     width: 30%;
    //     display: flex;
    //     flex-direction: column;
    //     gap: 0.5rem;
    //     .img-section {
    //       width: 100%;
    //       background-color: #eaeaeb;
    //       border-radius: 10px;
    //       display: flex;
    //       align-items: center;
    //       justify-content: center;
    //     }
    //     .text-section {
    //       width: 100%;
    //       display: flex;
    //       justify-content: space-between;
    //       .left-side {
    //         .name {
    //           font-size: 20px;
    //           font-weight: 400;
    //           line-height: 26px;
    //           letter-spacing: 0em;
    //           text-align: left;
    //           color: #101010;
    //         }
    //         .role {
    //           font-size: 16px;
    //           font-weight: 400;
    //           line-height: 21px;
    //           letter-spacing: 0em;
    //           text-align: left;
    //           color: #383838;
    //         }
    //       }
    //       .right-side {
    //         display: flex;
    //         align-items: center;
    //         gap: 0.5rem;
    //       }
    //     }
    //   }
    // }
  .ant-row{
    padding-top: 3rem;
    .ant-col {
      // width: 30%;
      height: 397px;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .img-section {
        height: 330px;
        background: url("../../assets/images/team-bg.webp");
        background-position: bottom;
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 10px;
    text-align: center;
        // display: flex;
    // align-items: center;
    // justify-content: center;
  
    //    flex-wrap: wrap;
    //    position: relative;
      //  img{
      //   position: relative;
      //   bottom: 0;
      //  }
      img{
        height: 330px;
      }
      }
      .text-section {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .left-side {
          .name {
            font-size: 20px;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0em;
            text-align: left;
            color: #101010;
          }
          .role {
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: left;
            color: #383838;
          }
        }
        .right-side {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
      }
    }
  }
  }
}

// @include breakpoint(mobile) {
//   .teams-section {
//     padding: 4rem 0;
//     .teams-container {
//       width: 85%;
//       margin: 0 auto;
//       .heading {
//         background: url("../../assets/images/ourTeam.webp");
//         background-position: center;
//         background-repeat: no-repeat;
//         background-size: contain;
//         font-size: 20px;
//         font-weight: 500;
//         line-height: 30px;
//         letter-spacing: 0em;
//         text-align: left;

//         color: #000000;
//         margin-bottom: 1rem;
//       }
//       .card {
//         .text-section {
//           .left-side {
//             .name {
//               font-size: 16px;
//               font-weight: 400;
//               line-height: 20px;
//               letter-spacing: 0em;
//               text-align: left;

//               color: #101010;
//             }
//             .role {
//               font-family: Poppins;
//               font-size: 13px;
//               font-weight: 400;
//               line-height: 16px;
//               letter-spacing: 0em;
//               text-align: left;

//               color: #383838;
//             }
//           }
//         }
//       }
//     }
//   }
// }

@include breakpoint(mobile){
  .our-teams-section {
    .teams-container{
      .cards-section{
        .card-container{
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

}
