//colors
$white: #ffffff;
$primary:#DE6B32;
$lightOrange:#FFF7EC;
$black: #261E1E;
$black1: #251F17;
$grey:#544E47;
$lightgrey: #76726E;
$lightyello: #FFF6C9;
$blackb1: #261E1E;
$blackb2: #1E1E1E;
$blackb3: #251F17;
$blackb4: #252525;
$blackb5: #171717;
$orangedark: #EB5851;
$lightyellow: #FFF6C9;
$grey: #544E47;
$greyg1: #645C5C;
$greyg2: #D9D9D9;
$greyg3: #D6D6D6;
$greyg4: #282828;
$greyg5: #4C2E31;
$greyg6: #E3E3E3;
$white: #FFFFFF;
$black: #000000;
$primary: #DE6B32;
$green: #5CAF30;
$gold: #DAC46F;
