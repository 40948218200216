@mixin breakpoint($point) {
	@if $point==xlgdesktop {
		@media (max-width: 1700px) {
			@content;
		}
	} @else if $point==lgdesktop {
		@media (max-width: 1600px) {
			@content;
		}
	} @else if $point==mldesktop {
		@media (max-width: 1500px) {
			@content;
		}
	} @else if $point==mddesktop {
		@media (max-width: 1368px) {
			@content;
		}
	} @else if $point==desktop {
		@media (max-width: 1280px) {
			@content;
		}
	} @else if $point==smdesktop {
		@media (max-width: 1024px) {
			@content;
		}
	} 
	@else if $point==tablet {
		@media (max-width: 820px) {
			@content;
		}
		
	}
	@else if $point==mobile {
		@media (max-width: 600px) {
			@content;
		}
	}
}