@import "../../assets/scss/variable.scss";
@import "../../assets/scss/mixin.scss";

.our-founding-section {
  // padding-top: 8rem;
  // padding-bottom: 4rem;
  padding: 4rem 0;
  .our-founding-container {
    width: 85%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    .top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left-side {
        .sub-heading {
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          letter-spacing: 0em;
          text-align: left;
          color: $primary;
        }
        .heading {
          font-size: 40px;
          font-weight: 500;
          line-height: 60px;
          letter-spacing: 0em;
          text-align: left;
          color: $black;
        }
      }
      .right-side {
        width: 602px;
        .text {
          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0em;
          text-align: left;

          span {
            font-weight: 500;
          }
        }
      }
    }
    .medium-section {
      height: 452px;
      .img-container {
        position: relative;
        z-index: 1;
               .img {
          width: 278px;

          position: absolute;
          top: 0;
          left: 0;
          img {
            width: 100%;
          }
        }
        .img2 {
          z-index: 10;
          margin-left: 14rem;
        }
        .img3 {
          z-index: 10;
          margin-left: 28rem;
        }
        .img4 {
          z-index: 10;
          margin-left: 42rem;
        }
        .img5 {
          z-index: 10;
          margin-left: 56rem;
        }
      }
    }
    .bottom-section {
      .heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 36px;
        font-weight: 300;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: center;

        span {
          width: 671px;
          height: 68px;
          font-weight: 500;
          background-color: #f0f8ff;
          border-radius: 10px;
          text-align: center;
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .our-founding-section {
    padding: 4rem 0;
    .our-founding-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 4rem;
      .top-section {
        width: 85%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        .left-side {
          .sub-heading {
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: center;

            color: $primary;
          }
          .heading {
            font-size: 20px;
            font-weight: 500;
            line-height: 31px;
            letter-spacing: 0em;
            text-align: center;
            color: $black;
          }
        }
        .right-side {
          width: 100%;
          .text {
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: center;
            span {
              font-weight: 500;
            }
          }
        }
      }
      .medium-section {
        height: 100%;
        .img-container {
          position: relative; 
          img {
            width: 100%;
          } 
      }
    }
      .bottom-section {
        width: 85%;
        margin: 0 auto;
        .heading {
          font-size: 14px;
          font-weight: 300;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: center;

          span {
            width: 278px;
            height: 28px;
            font-weight: 500;
            border-radius: 4px;
          }
        }
      }
    }
  }
}
