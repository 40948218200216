@import "../../assets/scss/mixin.scss";


.chat-section {
  padding-bottom: 200px;
  .chat-container {
    background: url("https://gati-public.s3.ap-south-1.amazonaws.com/websiteAssets/assetsForLandingPage/assets/images/chatBg.svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 1018px;
    margin: 0 auto;
    height: 526px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .right-side {
        height: 367px;
        width: 495px;
        padding: 2rem;
        border: 2px solid #E25E3E;
        background-color: #FEECC0;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      .heading {
        font-size: 36px;
        font-weight: 700;
        line-height: 48px;
        letter-spacing: 0em;
        color: #de6b32;
      }
      .tik-container {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        .tik-content {
          display: flex;
          gap: 1rem;
          .text {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: 0em;
            color: #de6b32;
          }
        }
      }
    }
  }
}


@include breakpoint (mobile){
  .chat-section {
    margin-bottom: 4rem;
    .chat-container {
      background: url("../../assets/images/chat-mobile-bg.webp");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column-reverse;
      justify-content: space-around;
      .left-side{
        width: 300.99px;
        height: 230px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .right-side {
          height:222.78px;
          width: 297.58px;
          padding: 1rem;
          border: 2px solid #E25E3E;
          background-color: #FEECC0;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        .heading {
font-size: 16px;
font-weight: 700;
line-height: 24px;
letter-spacing: 0em;
text-align: left;

          color: #de6b32;
        }
        .tik-container {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          .tik-content {
            display: flex;
            gap: 1rem;
            .text {
font-size: 11px;
font-weight: 500;
line-height: 17px;
letter-spacing: 0em;
text-align: center;

              color: #de6b32;
            }
            img{
              width: 14.57px;
              aspect-ratio: 1/1;
            }
          }
        }
      }
    }
  }
  
}