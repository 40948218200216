.customer-story-section {
  padding-top: 60px;
 
  background-color: #ffffff;
  .readMoreBtn {
    border: none;
    outline: none;
    width: 168px;
    height: 46px;
    background-color: #261e1e;
    border-radius: 7px;

    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.025em;
    color: #ffffff;
    &:hover{
      color: #FFFFFF !important;
    }
  }
  .heading {
    font-size: 40px;
    font-weight: 400;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
    color: #261e1e;
  }
  .text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #6e6868;
  }
  .top-section {
    background-color: #fff1a8;
    height: 634px;
    margin-bottom: 11rem;
    .top-container {
      width: 85%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      padding-top: 5rem;
      .left-side {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
       
      }
      .right-side {
        width: 616px;
        img {
          width: 100%;
        }
      }
    }
  }
  .bottom-section {
    padding-bottom: 6rem;
    .top{
        background-color: #effde4;
    }
    .bottom{
        background-color: #FDFCE0;
    }
    .top, .bottom {
      .customer {
        width: 85%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4rem 0;
        .left-side {
          width: 514px;
          img {
            width: 100%;
          }
        }
        .right-side {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          .customer-1 {
            width: 230px;
            padding: 8.85px;
            border-radius: 42.46px;
            background-color: #4262c5;
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            letter-spacing: 0em;
            text-align: center;
            color: #ffffff;
            text-transform: uppercase;
          }
          .sub-heading {
            font-size: 36px;
            font-weight: 400;
            line-height: 54px;
            letter-spacing: 0em;
            text-align: left;
            color: #261e1e;
          }
        }
      }
    }
    .bottom {
    }
  }
}
