.gallery-img-section{
    padding-bottom: 6rem;
    .ant-row{
        .ant-col{
            height: 701px;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
}

.jockey-gallery-img-section{
    padding-bottom: 6rem;
    .ant-row{
        .ant-col{
            height: 472px;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
}