.brands-section{
    padding-top: 4rem;
    padding-bottom: 8rem;
    .brands-container{
        width: 85%;
        margin: 0 auto;
        .heading{
font-size: 38px;
font-weight: 500;
line-height: 57px;
letter-spacing: 0em;
text-align: center;
color: #000000;
        }
        .brand-content{
            width: 80%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 2rem;
            .col{
                display: flex;
                flex-direction: column;
                gap: 2rem;
                .img{
                    height: 96px;
                    width: 170px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 2rem;
                    box-shadow: 0px 30px 40px 0px #D4D9E833;
                    border-radius: 1rem;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}