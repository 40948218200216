.waiting-section{
    background-color: #FFF7EC;
    padding: 4rem 0;
    .waiting-container{
display: flex;
flex-direction: column;
align-items: center;
.heading{

font-size: 40px;
font-weight: 600;
line-height: 64px;
letter-spacing: -0.02em;
text-align: center;
color: #261E1E;
}
.sub-heading{

font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color: #76726E;
}
.button{
    padding-top: 1rem;
display: flex;
align-items: center;
justify-content: center;
gap: 0.5rem;
.primaryBtn{
    border: none;
    outline: none;
    width: 191.6px;
    height: 50px;
    background-color: #DE6B32;
    color: #FFFFFF;
font-size: 14px;
font-weight: 600;
line-height: 21px;
letter-spacing: 0em;
text-align: center;
text-transform: uppercase;

  }
  .secondaryBtn{
    border: none;
    outline: none;
    width: 214px;
    height: 50px;
    background-color: transparent;
    color: #DE6B32;
font-size: 14px;
font-weight: 600;
line-height: 21px;
letter-spacing: 0em;
text-align: center;
border: 1px solid #DE6B32;
text-transform: uppercase;
  }
}
    }
}