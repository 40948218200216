.card {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    width: 20rem;
    height: fit-content;
    /* padding: 0 2rem 2rem 2rem; */
    /* border-radius: 10px; */
  }
  @media screen and (max-width: 767px){
    .card{
        width: 13rem;
    }
  }
  
  .card img {
    /* margin-top: -60%; */
    width: 100%;
    /* border-radius: 20px; */
  }
  