.action-section {

  .action-container {
    padding-top: 8rem;
    padding-bottom: 25rem;
    .top-section {
        width: 85%;
        margin: 0 auto;
      .heading {
        font-family: Poppins;
        font-size: 48px;
        font-weight: 500;
        line-height: 67px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #010300;
      }
      .text-container {
        display: flex;
        justify-content: space-between;
        .text {
            width: 45%;
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0em;
          text-align: left;
          color: #261e1eab;
        }
      }
    }
    .bottom-section{
        height: 446px;
        background: url("../../assets/images/customer-1-action.webp"); 
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
       .bottom-content{
        position: relative;
            top: 70%;
        .bottom-container{
            
            width: 85%;
            margin: 0 auto;
            display: flex;
            
            justify-content: space-between;
            .box{
                width: 32%;
                background-color: #FFFFFF;
                padding: 1.5rem;
                padding-top: 5rem;
                border: 0.5px solid #CAD6F1;
                box-shadow: 0px 25px 50px 0px #16194F0D;
border-radius: 10px;
                .img{
                    position: absolute;
                    top: -4rem;
                }
                .box-text{
                    font-family: Poppins;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 27px;
                    letter-spacing: 0em;
                    text-align: left;
color: #010300;                    
                }
            }
        }
        .sub-text{
            width: 60%;
            margin: 1rem auto;
font-size: 16px;
font-weight: 400;
line-height: 27px;
letter-spacing: 0em;
text-align: center;
color: #010300;
        }
       }

    }
  }
}

.action-section-customer-2 {
  background-color: #F1E1C736;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6rem;
  .right-side {
    width: 45%;
    img {
      width: 100%;
    }
  }
  .left-side {
    width: 50%;
    padding: 5rem 0;
    padding-left: 3rem;
    .heading {
      font-family: Poppins;
      font-size: 40px;
      font-weight: 500;
      line-height: 60px;
      letter-spacing: 0em;
      text-align: left;
      color: #261e1e;
      border-left: 4px solid #de6b32;
      padding-left: 1rem;
    }
  
    .alice-carousel {
     
      position: relative;
      .text {
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: #261e1eab;
        padding-right: 3rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 3rem;
      }
      .text-2 {
        padding-top: 2rem;
        padding-right: 5rem;
      }
      .text-3{
        padding-left: 0rem;
      }
      .alice-carousel__dots{
        position: absolute;
        .alice-carousel__dots-item:not(.__custom){
            width: 14px;
            height: 14px;
            background-color: #D9D9D9;
            border-radius: 2px;
        }
        .alice-carousel__dots-item:not(.__custom).__active{
            background-color: #D6A94F;
        }
      }
    }
   
  }

}
