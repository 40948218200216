.smashed-story-section {
  padding-bottom: 6rem;
  .story-container {
    .top-section {
      font-size: 56px;
      font-weight: 300;
      line-height: 66px;
      letter-spacing: -0.06em;
      text-align: center;
      color: #000000;
      span {
        font-weight: 500;
      }
    }
    .middle-section {
      .img-section {
        padding: 2rem 0 1rem;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        .img {
          width: 24%;
          height: 453px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .text-section {
        padding: 0 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 27px;
        font-weight: 275;
        line-height: 32px;
        letter-spacing: -0.06em;
        text-align: left;
        color: #000000;
        .right-side {
          display: flex;
          gap: 1rem;
          .story {
            font-weight: 300;
            span {
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

.img-section {
  padding-bottom: 6rem;
  .img-container {
    width: 85%;
    margin: 0 auto;
    .heading {
      font-size: 40px;
      font-weight: 400;
      line-height: 47px;
      letter-spacing: -0.06em;
      text-align: left;
      color: #261e1e;
    }
    .text {
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.06em;
      text-align: left;
      color: #2c2c2c;
      padding-top: 0.5rem;
    }
    .middle-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 1rem;
      .left-img,
      .right-img {
        width: 318px;
        height: 364px;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .middle-img {
        width: 393px;
        height: 642px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
