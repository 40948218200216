@import "../../assets/scss/mixin.scss";

.satisfied-section {
  padding-bottom: 12.5rem;
  .satisfied-container {
    width: 80%;
    margin: 0 auto;
    .top-section {
      .heading {
        font-size: 36px;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: 0em;
        text-align: center;
        color: #251f17;
      }
      .text {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: center;
        color: #544e47;
        padding: 0.5rem 0 1rem;
      }
    }
    .customer-section {
      position: relative;
      display: block;
      margin: 0 auto;
      height: 751px;
      background: url("https://gati-public.s3.ap-south-1.amazonaws.com/websiteAssets/assetsForLandingPage/assets/images/rectangle6.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      padding: 3rem 0;
      .css-doq0dk {
        position: relative;
        .css-1fzpoyk {
          // width: 28% !important;
          // z-index: 1 !important;
          opacity: 1 !important;
        }
      }
      .text-container {
        width: 600px;
        margin: 0 auto;
        padding: 2rem 4rem 1rem;
        .carousel-text {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: #251f17;
        }
      }
      .carousel-header {
        width: 600px;
        margin: 0 auto;
        padding: 0 5rem;

        span {
          font-weight: 700;
        }

        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        color: #03749d;
      }

      .dot-container {
        align-self: end;
        width: 600px;
        margin: 0 auto;
        .dots-content {
          width: 114px;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 2rem 0;
          .dot {
            width: 10px;
            aspect-ratio: 1/1;
            background-color: #d9d9d9;
            border-radius: 50px;
            &:hover {
              cursor: pointer;
            }
          }
          .active-dot {
            background-color: #03749d;
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .satisfied-section {
    margin-bottom: 4rem;
    .satisfied-container {
      width: 100%;
      margin: 0 auto;
      .top-section {
        .heading {
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: center;

          color: #251f17;
        }
        .text {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: center;
          color: #544e47;
          padding: 0.5rem 0 1rem;
        }
      }
      .customer-section {
        height: 800px;
        padding: 2rem 0;
        background-size: cover;
        .text-container {
          // width: 600px;
          // margin: 0 auto;
          padding:1rem;
          width: 100%;
          .carousel-text {
            font-size: 14px;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: left;
            
            color: #251f17;
          }
        }
        .carousel-header {
          width: 100%;
          font-size: 13px !important;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: center;
          padding: 0 1rem;
          span {
            font-weight: 700;
          }

          font-size: 18px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0em;
          color: #03749d;
        }

        .dot-container {
          width: 100%;
        }
      }
    }
  }
}
