.our-vision-section{
    padding-top: 6rem;
    padding-bottom: 6rem;
    .our-vision-container{
       
        display: flex;
        flex-direction: column;
        gap: 3rem;
        .top-section{
            width: 85%;
            margin: 0 auto;
            .heading{
font-size: 100px;
font-weight: 400;
line-height: 118px;
letter-spacing: -0.06em;
text-align: left;
color: #000000;
span{
    font-weight: 600;
}
            }
            .sub-heading{

font-size: 28px;
font-weight: 300;
line-height: 33px;
letter-spacing: -0.06em;
text-align: left;
color: #000000;

            }
        }
        .bottom-section{
            img{
                width: 100%;
            }
        }
    }
}



.aspiration-section{

     padding-top: 4rem;
    padding-bottom: 6rem;
    .aspiration-container{
        .img-section{
         display: flex;
         align-items: center;
         justify-content: space-between;
      
        //  height: 651px;
         .img1{
width: 69.5%;
height: 651px;
img{
    height: 100%;
    width: 100%;
 }
         }
         .img2{
            width: 29.5%;
            height: 651px;
            img{
                height: 100%;
                width: 100%;
             }
         }
        

        }
        .text-section{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 2rem;
            .text{
                
font-size: 107px;
font-weight: 300;
line-height: 127px;
letter-spacing: -0.06em;
text-align: center;
color: #CFCFCF;

            }
            .heading{
                font-family: Poppins;
font-size: 28px;
font-weight: 600;
line-height: 33px;
letter-spacing: 0.415em;
text-align: center;
color: #BC2B33;

            }
        }
    }
}



.knowledge-section {
    padding-top: 5rem;
    padding-bottom: 6rem;
    .knowledge-container {
      .img-section {
        width: 85%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        gap: 1rem;
        height: 685px;
        .left {
          // height: 400px;
          img {
            width: 100%;
          }
        }
        .right {
          // height: 400px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 1rem;
          img {
            width: 100%;
          }
        }
      }
      .text-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 3rem;
        .text {
          font-size: 85px;
          font-weight: 400;
          line-height: 101px;
          letter-spacing: -0.06em;
          text-align: center;
  
          color: #cfcfcf;
        }
        .heading {
      
          font-size: 28px;
          font-weight: 600;
          line-height: 33px;
          letter-spacing: 0.415em;
          text-align: center;
          color: #bc2b33;
        }
      }
    }
  }


  .china-section{
    padding-top: 4rem;
    padding-bottom: 6rem;
    .china-container{
        width: 85%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left-side{
           
            .title{
font-size: 21px;
font-weight: 300;
line-height: 32px;
letter-spacing: 0em;
text-align: left;
color: #251F17;
display: flex;
gap: 3rem;
            }
            .heading{
            span{
                font-style: italic;
                font-weight: 600;
            }



font-size: 52px;
font-weight: 500;
line-height: 64px;
letter-spacing: -0.055em;
text-align: left;
color: #251F17;
            }
            .text{
                font-family: Poppins;
font-size: 20px;
font-weight: 400;
line-height: 24px;
letter-spacing: -0.06em;
text-align: left;
color: #251F1796;
padding-top: 1.5rem;
            }
        }
        .right-side{
            width: 50%;
            img{
                width: 100%;
            }
        }
    }
}

.jockey-section{

  margin-top: 4rem;
  margin-bottom: 6rem;
  background-image: url("../../assets/images/gallery_5-banner.webp");
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
  height: 800px;
  .jockey-container{
    height: 100%;
    width: 85%;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .title{
      font-size: 21px;
      font-weight: 300;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
      color: #251F17;
      display: flex;
      align-items: center;
      gap: 2rem;
                  }
                  .heading{
                  span{
                      font-style: italic;
                      font-weight: 600;
                  }
      
      
      
      font-size: 52px;
      font-weight: 500;
      line-height: 64px;
      letter-spacing: -0.055em;
      text-align: left;
      color: #251F17;
      padding-bottom: 1rem;
                  }
  }
}
  