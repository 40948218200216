.affiliate-section {
  margin-bottom: 8rem;
  .affiliate-container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .left-side {
        // width: 40%;
        img{
            width: 100%;
        }
    }
    .right-side {
        width: 55%;
      .heading {
        font-size: 40px;
        font-weight: 600;
        line-height: 64px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #261e1e;
      }
      .sub-heading {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #544e47;
      }
      .content{
        padding: 3rem 0;
        .ant-row{
            .ant-col{
                .item{
                    display: flex;
                    justify-content: space-between;
                    gap: 1rem;
                    .left-1{
                        height: 74px;
                        border-left: 3px solid #FF4057;
                    }
                    .left-2{
                        height: 55px;
                        border-left: 3px solid #F79F24;
                    }
                    .left-3{
                        height: 62px;
                        border-left: 3px solid #0960BD;
                    }
                    .left-4{
                        height: 62px;
                        border-left: 3px solid #F05D23;
                    }
                    .left-5{
                        height: 62px;
                        border-left: 3px solid #B206B0;
                    }
                    .right{
                        .title{
                            font-family: Poppins;
                            font-size: 20px;
                            font-weight: 600;
                            line-height: 30px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: #251F17;
                        }
                        .sub-title{
                            font-family: Poppins;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 24px;
                            letter-spacing: 0em;
                            text-align: left;
color: #76726E;                            
                        }
                    }
                }
            }
        }
      }
    }
  }
}
