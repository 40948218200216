.worth-section {
    margin-bottom: 8rem;
    .top-section {
      height: 798px;
      background: url("../../assets/images/customer-2-banner.webp");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      .top-container {
        width: 85%;
        margin: auto;
        .left-side {
          width: 50%;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          .title {
            font-size: 40px;
            font-weight: 500;
            line-height: 64px;
            letter-spacing: -0.02em;
            text-align: left;
            color: #FFFFFF;
          }
          .button {
            .primaryBtn {
              border: none;
              outline: none;
              background-color: #FFFFFF;
              width: 236px;
              height: 50px;
  
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              letter-spacing: 0em;
              text-align: center;
              color: #010300;
            }
          }
          .percent-container{
            display: flex;
            align-items: center;
            gap: 3rem;
            .percent{
            
font-size: 32px;
font-weight: 600;
line-height: 48px;
letter-spacing: 0em;
text-align: left;
color: #FFFFFF;
            }
            .text{
           
font-size: 16px;
font-weight: 400;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
color: #FFFFFF;
            }
          }
        }
      }
    }
    .bottom-section {
      width: 85%;
      margin: 1rem auto;
      .text {
        font-size: 32px;
        font-weight: 400;
        line-height: 56px;
        letter-spacing: 0em;
        text-align: left;
        color: #ABABAB;
        span {
          font-weight: 500;
          color: #261E1E;
        }
      }
    }
  }
  