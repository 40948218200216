
.call-section{
    padding: 6rem 0;
    .call-container{
        width: 85%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left-side{
position: relative;
.call-img{
    width: 100%;
}
.call-text{
    position: absolute;
    top: -4rem;
    left: 12rem;
}
        }
        .right-side{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .heading{
                font-family: Poppins;
font-size: 64px;
font-weight: 600;
line-height: 76px;
letter-spacing: -0.06em;
text-align: center;
color: #2C2C2C;
            }
            .tic-section{
                .tic-content{
                    display: flex;
                    align-items: center;
            gap: 1rem;
            padding: 0.5rem 0;
                    .text{
                        font-family: Poppins;
font-size: 20px;
font-weight: 400;
line-height: 24px;
letter-spacing: -0.06em;
text-align: center;
color: #2C2C2C;
                    }
                }
            }
            .button-section{
               
                .btn{
                    border: none;
                    outline: none;
                    width: 198.44px;
                    height: 46.8px;
                    border: 2px solid #2C2C2C;
                    border-radius: 7px;
font-size: 15px;
font-weight: 600;
line-height: 22px;
letter-spacing: -0.06em;
text-align: center;
color: #2C2C2C;
background-color: transparent;
                }
            }
        }
    }
}