.client-section{
    background-color: #FBFBFB;
    padding: 4.5rem 0;
    .client-container{
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .left-side{
            width: 45%;
            display: flex;
            align-items: center;
            gap: 1rem;
            .right-side-img{
                display: flex;
                flex-direction: column;
                gap: 1rem;
            }
        }
        .right-side{
            width: 40%;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .heading{
                font-family: Poppins;
                font-size: 40px;
                font-weight: 500;
                line-height: 60px;
                letter-spacing: 0em;
                text-align: left;
                color: #261E1E;
                border-left: 4px solid #DE6B32;
                padding-left: 1rem;
            }
            .text{
                font-family: Poppins;
font-size: 20px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
color: #261E1EAB;
            }
            .sub-title{
                font-family: Poppins;
font-size: 18px;
font-style: italic;
font-weight: 500;
line-height: 27px;
letter-spacing: 0em;
text-align: left;
color: #261E1E;
            }
            .primaryBtn{
                border: none;
                outline: none;
                width: 217px;
                height: 46px;
                background-color: #261E1E;
          
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: -0.025em;

color: #fff;
            }
        }
    }
}
.customer-2-client{
    padding-bottom: 6rem;
    .left-side{
        width: 45%;
       
    img{
            width: 100%;
        }
    }
}