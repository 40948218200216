@import "../../assets/scss/variable.scss";
@import "../../assets/scss/mixin.scss";

.elevate-section {
  .elevate-container {
    position: relative;
    width: 85%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-top: 8rem;
    padding-bottom: 4rem;
    .circle-top {
      position: absolute;
      right: -7.5%;
      img {
        width: 100%;
      }
    }
    .circle-bottom {
      position: absolute;
      top: 28.5rem;
      right: -7.5%;
      img {
        width: 100%;
      }
    }
    .left-side {
      .heading {
        span {
          font-weight: 700;
          color: $primary;
        }
        font-size: 40px;
        font-weight: 500;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: left;
        color: $blackb1;
      }
      .sub-heading {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
        color: $greyg4;
        padding-top: .5rem;
      }
      
    }
    .right-side {
      align-self: flex-end;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;
      padding-top: 3rem;
      .arrow{}
      .rectangle {
        background-color: $white;
        box-shadow: 0px 0px 20px 0px #0000001a;
        border-radius: 8px;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: $blackb4;
        padding: 0.5rem;
      }
      .content {
        display: flex;
        align-items: center;
        gap: 1rem;
        .number {
          font-size: 24px;
          font-weight: 700;
          line-height: 36px;
          letter-spacing: 0em;
          text-align: left;
          color: $blackb4;
          span {
            font-weight: 600;
            color: $primary;
          }
        }
        .text {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: left;
          color: $blackb4;
        }
      }
    }
  }
}



@include breakpoint(tablet){
  .elevate-section {
    .elevate-container {
      .left-side {
        .heading {
          span {
            font-weight: 700;
            color: $primary;
          }
          font-size: 20px;
          font-weight: 400;
          line-height: 30px;
          
        }
        .sub-heading {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
        }
        
      }
      .right-side {
       
        gap: 1rem;
        padding-top: 3rem;
        .arrow{}
        .rectangle {
          box-shadow: 0px 0px 14.784466743469238px 0px #0000001A;
          border-radius: 5.91px;
          font-size: 8.87px;
          font-weight: 400;
          line-height: 13.31px;
         
        }
        .content {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          .number {
            font-size: 17.74px;
            font-weight: 700;
            line-height: 26.61px;
            
            span {
              font-weight: 600;
              color: $primary;
            }
          }
          .text {
            font-size: 8.87px;
            font-weight: 400;
            line-height: 13.31px;
          }
        }
      }
    }
  }
}


@include breakpoint(mobile){
  .elevate-section {
    .elevate-container{
      width: 85%;
      margin: 0 auto;
      padding-top: 4rem;
      background-image: url("../../assets/images/elevate-mobile-bg.webp");
      background-repeat: no-repeat;
      background-position: right bottom;
      .top-container{
        display: flex;
        flex-direction: column;
        align-items: center;
.title{
  font-size: 20px;
font-weight: 500;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
color: $blackb1;
span{
  font-size: 20px;
font-weight: 700;
line-height: 31px;
letter-spacing: 0em;
text-align: center;
color: $primary;
}

}
.sub-title{
  font-family: Poppins;
font-size: 14px;
font-weight: 400;
line-height: 21px;
letter-spacing: 0em;
text-align: center;
color: #282828;

}

      }
      .bottom-container{
        padding-top: 2rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
       

.top-side{
  display: flex;
  align-items: center;
  .rectangle{
    background-color: $white;
        box-shadow: 0px 0px 20px 0px #0000001a;
        border-radius: 5.45px;
font-size: 10px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: left;

        color: $blackb4;
        padding: 0.5rem;
  }
}
.bottom-side{
  display: flex;
  align-items: center;
  justify-content: space-around;
  .content{
    .number{
font-size: 19px;
font-weight: 600;
line-height: 29px;
letter-spacing: 0em;
text-align: left;
      span{
        font-weight: 700;
        color: #DE6B32;
      }
    }
    .text{
      color: #252525;
font-size: 10px;
font-weight: 400;
line-height: 15px;
letter-spacing: 0em;
text-align: left;

    }
  }

}
      }

    }
  }
}