@import "../../assets/scss/variable.scss";
.success-section {
  margin-bottom: 8rem;
  .success-container {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    .title-heading {
            font-size: 40px;
      font-weight: 600;
      line-height: 64px;
      letter-spacing: -0.02em;
      color: #261E1E;
      margin: 0;
    }
    .title-text {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      color: #544E47;
    }
    .ant-row {
      margin-top: 3rem;
      text-align: start;
      .item {
        img {
          width: 58px;
          aspect-ratio: 1/1;
          object-fit: contain;

        }
        .subheading {
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
          letter-spacing: 0em;
          color: #251F17;
          padding: .5rem 0;
        }
        .subtext {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: #76726E;
        }
      }
    }
  }
}
