@import "../../assets/scss/variable.scss";
.lets-grow-section {
  height: 723px;
  background: url("../../assets/images/blue-banner-img.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8rem;
  .lets-grow-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    column-gap: 1rem;
    .title-heading {
      font-size: 50px;
      font-weight: 600;
      line-height: 64px;
      letter-spacing: -0.02em;
      color: #FFFFFF;
    }
    .title-text {
     
            font-size: 18px;
      line-height: 32.75px;
      letter-spacing: 0em;
      font-weight: 400;
      color: #FFFFFF;
      padding-bottom: 2rem;
      span {
        font-weight: 600;
        text-decoration: underline;
      }
    }
    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      .primaryBtn{
        border: none;
        outline: none;
        width: 191.6px;
        height: 50px;
        background-color: #FFFFFF;
        color: #4F4F4F;
font-size: 14px;
font-weight: 600;
line-height: 21px;
letter-spacing: 0em;
text-align: center;


      }
      .secondaryBtn{
        border: none;
        outline: none;
        width: 186px;
        height: 50px;
        background-color: transparent;
        color: #FFFFFF;
font-size: 14px;
font-weight: 600;
line-height: 21px;
letter-spacing: 0em;
text-align: center;
border: 1px solid #FFFFFF;
      }
    }
  }
}
