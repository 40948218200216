@import "../../assets/scss/mixin.scss";
.offers-section {
  padding-bottom: 12.5rem;
  .offer-container {
    width: 85%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    .top-section {
      .heading {
        font-size: 36px;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: 0em;
        text-align: center;
        color: #251f17;
      }
      .text {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: center;
        color: #544e47;
      }
    }


    .bottom-section {
      width: 100%;
      .ant-row {

        .ant-col {
          .col {
            display: flex;
            height: 99%;
            .content {
              // height:  44.5vh;
              border-radius: 0px 20px 20px 0px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding: 2rem;
              .item-title {
                font-size:30px;
                font-weight: 600;
                line-height: 45px;
                letter-spacing: 0em;
                color: #252628;
              }
              .offer-section {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                .get {
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 14px;
                  letter-spacing: 0em;
                  color: #080131;
                }
                .offer {
                  color: #001b49;
                  font-size: 6.25rem;
                  font-weight: 800;
                  line-height: 86px;
                  letter-spacing: 0em;
                  span {
                    font-size: 29px;
                    font-weight: 500;
                    line-height: 25px;
                    letter-spacing: 0em;
                  }
                }
                .only-text {
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 24px;
                  letter-spacing: 0em;

                  color: #252628;

                  .bold {
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 36px;
                    letter-spacing: 0em;
                  }
                  .grey {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 21px;
                    letter-spacing: 0em;
                    text-decoration: line-through;
                    color: #2526289c;
                  }
                }
              }
              .item-text {
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0em;
                text-align: left;
                color: #544e47;
              }
            }
          }
          .first-col {
            height: 463px;
            .img {
              position: relative;
              .off-icon {
                position: absolute;
                z-index: 1;
                top: -2.5rem;
                right: -3rem;
              }
              .offer-img{
                // width: 34rem;
                height: 100%;
                width: 100%;
              }
            }
            .content {
              width: 728px;
              background-color: #c4d0e4;
            }
          }
          .sec-col {
            height: 463px;
            .img{
              width: 40%;
             display: flex;
             align-items: center;
             justify-content: center;
              img{
                border-radius: 20px 0px 0px 20px !important;
                width: 100%;
                height: 100%;
              }
            }
            
            .content {
              width: 60%;
              background-color: #F5DCA1;
             
            }
          }
          
          .third-col {
            height: 463px;
          
            .img{
              border-radius: 20px 0px 0px 20px;
              width: 40%;
               display: flex;
               align-items: center;
               justify-content: center;
               background-color: #EAF6F6;
              
            }
            
            .content {
              width: 60%;
              background-color: #dfecf1;
             
            }
          }
        }
      }
    }
  }
}


@include breakpoint(mobile){
  .offers-section {
    margin-bottom: 4rem;
    .offer-container {
      width: 80%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
  
      .top-section {
        .heading {
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: center;          
          color: #251f17;
        }
        .text {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: center;          
          color: #544e47;
        }
      }
      .bottom-section {
        width: 100%;
        .ant-row {
  
          .ant-col {
            .col {
              display: flex;
              flex-direction: column;
              gap: 0;
              .content {
                border-radius: 0px 0px 20px 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 1rem;
                .item-title {
                  font-size: 20px;
                  font-weight: 700;
                  line-height: 33px;
                  letter-spacing: 0em;
                  text-align: left;
                  
                  color: #252628;
                }
                .offer-section {
                  display: flex;
                  flex-direction: column;
                  gap: 1rem;
                  .get {
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 11px;
                    letter-spacing: 0em;
                    text-align: left;
                    
                    color: #080131;
                  }
                  .offer {
                    color: #001b49;
                    font-size: 83.12px;
                    font-weight: 800;
                    line-height:71.06px;
                    letter-spacing: 0em;
                    span {
                      font-size: 23.95px;
                      font-weight: 500;
                      line-height: 20.48px;
                      letter-spacing: 0em;
                    }
                  }
                  .only-text {
                    font-size: 15.35px;
                    font-weight: 600;
                    line-height:23.1px;
                    letter-spacing: 0em;
  
                    color: #252628;
  
                    .bold {
                      font-size: 23.02px;
                      font-weight: 600;
                      line-height: 34.64px;
                      letter-spacing: 0em;
                    }
                    .grey {
                      font-size: 14px;
                      font-weight: 500;
                      line-height: 21px;
                      letter-spacing: 0em;
                      text-decoration: line-through;
                      color: #2526289c;
                    }
                  }
                }
                .item-text {
                  font-family: Poppins;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 22px;
                  letter-spacing: 0em;
                  text-align: left;                  
                  color: #544e47;
                  padding-top: 1rem;
                }
              }
            }
            .first-mobile-col {
              .img {
                position: relative;
                .offer-img{
                  width: 100%  ;
                  height: 100%;    
                 }
              }
              .content {
                width: 100%;
                background-color: #c4d0e4;
              }
            }
            .sec-mobile-col {
              .img {
                position: relative;
                .offer-img{
                  border-radius: 20px 20px 0px 0px ;
                  width: 100%  ;
                  height: 100%;    
                 }
              }
              .content {
                width: 100%;
                background-color: #F5DCA1;
              }
            }
            .third-mobile-col {
              .img {
                position: relative;
                width: 100%  ;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #EAF6F6;
                border-radius: 20px 20px 0px 0px ;
                .offer-img{
                
                  // height: 100%;    
                 
                 }
              }
              .content {
                width: 100%;
                background-color: #dfecf1;
              }
            }
          }
        }
      }
    }
  }
}

