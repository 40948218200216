@import "../../assets/scss/variable.scss";
.faq-section {
  // background-color: $bgColor;
  background-color: #fff7ec;
  padding: 5rem 0;
  margin-bottom: 8rem;
  .title-heading {
    font-size: 40px;
    font-weight: 600;
    line-height: 64px;
    letter-spacing: -0.02em;
    text-align: center;
    color: #261e1e;
    padding-bottom: 4rem;
  }
  .ant-collapse {
    width: 867px;
    margin: 0 auto;
    background-color: transparent !important;

    .ant-collapse-item {
      border-bottom: 1px solid #e4e4e4;
      .ant-collapse-header {
        padding: 1.2rem 0.8rem;
        align-items: center;
        .heading {
          font-size: 24px;
          font-weight: 500;
          line-height: 36px;
          letter-spacing: 0em;
          text-align: left;
          color: #251f17;
          margin: 0;
        }
        .ant-collapse-expand-icon{
         img{
          width: 20px;
         }

        }
      }
      .ant-collapse-content {
        padding-bottom: 1rem;
        .text {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: #716e6b;
        }
      }
    }
  }
}
