.generated-section{
    margin-bottom: 6rem;
    .generated-container{
        width: 85%;
        margin: 0 auto;
        .heading{
font-size: 40px;
font-weight: 500;
line-height: 60px;
letter-spacing: 0em;
text-align: center;
color: #261E1E;
        }
        .img-section{
            padding-top: 2rem;
            display: flex;
            justify-content: space-between;
          
            .first-col, .sec-col, .third-col{
display: flex;
flex-direction: column;
gap: 1.5rem;
.img{
    height: 613px;
    width: 392px;
    img{
        width: 100%;
        height: 100%;
    }
}
            }
            .sec-col{
                padding-top: 2rem;
            }
        }
    }
}