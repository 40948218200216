.leading-section {
  // .leading-container{

  // }
  margin-bottom: 8rem;
  .top-section {
    height: 554px;
    background: url("../../assets/images/customer-1-banner.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    .top-container {
      width: 80%;
      margin: auto;
      .left-side {
        width: 45%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .title {
          font-size: 40px;
          font-weight: 500;
          line-height: 64px;
          letter-spacing: -0.02em;
          text-align: left;
          color: #181818;
        }
        .button {
          .primaryBtn {
            border: none;
            outline: none;
            background-color: #181818;
            width: 236px;
            height: 50px;

            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
            color: #ffffff;
          }
        }
      }
    }
  }
  .bottom-section {
    width: 85%;
    margin: 1rem auto;
    .text {
      font-size: 28px;
      font-weight: 400;
      line-height: 49px;
      letter-spacing: 0em;
      text-align: left;
      color: #ABABAB;
      span {
        font-weight: 500;
        color: #261E1E;
      }
    }
  }
}
