.challenges-section {
  background-color: #f8fcfb;
  display: flex;
  justify-content: space-between;
  .left-side {
    padding: 5rem 0;
    padding-left: 3rem;
    .heading {
      font-family: Poppins;
      font-size: 40px;
      font-weight: 500;
      line-height: 60px;
      letter-spacing: 0em;
      text-align: left;
      color: #261e1e;
      border-left: 4px solid #de6b32;
      padding-left: 1rem;
    }
    .text {
      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
      color: #261e1eab;
      padding-right: 3rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    .text-2 {
      padding-top: 3rem;
      padding-right: 5rem;
    }
  }
  .right-side {
    img {
      // height: 100%;
    }
  }
}

.customer-2 {
  background-color: #d4c6d51f;
  .right-side {
    width: 45%;
    img {
      width: 100%;
    }
  }
  .left-side {
    width: 50%;
    .alice-carousel {
      padding-left: 3rem;
      position: relative;
      .text {
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: #261e1eab;
        padding-right: 3rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
      .text-2 {
        padding-top: 2rem;
        padding-right: 5rem;
      }
      .alice-carousel__dots{
        position: absolute;
        .alice-carousel__dots-item:not(.__custom){
            width: 14px;
            height: 14px;
            background-color: #D9D9D9;
            border-radius: 2px;
        }
        .alice-carousel__dots-item:not(.__custom).__active{
            background-color: #861F8D;
        }
      }
    }
  }
}
