@import "../../assets/scss/mixin.scss";

.specialist-section {
  padding: 8rem 0;
  .specialist-container {
    display: flex;
    width: 100%;
    height: 789px;
    .text-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      .text {
        font-size: 24px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        span {
          font-weight: 600;
        }
      }
      .specialistBtn {
        border: none;
        outline: none;
        width: 183px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        color: #282626;
        background-color: transparent;
        border: 1.5px solid #282626;
        img {
          width: 10px;
          aspect-ratio: 1/1;
        }
        &:hover {
          background-color: #282626;
          color: #ffffff;
        }
      }
    }
    .first-col {
      background-color: #faead6;
      display: flex;
      flex-direction: column;
      .text-container {
        height: 278px;
      }
      .img-container {
        height: 511px;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
    .sec-col {
      background-color: #1f1f21;
      display: flex;
      flex-direction: column;
      .text-container {
        height: 331px;
        .text {
          color: #ffffff;
          text-align: center;
        }
        .specialistBtn {
          color: #ffffff;
          background-color: transparent;
          border: 1.5px solid #ffffff;
          &:hover {
            background-color: #ffffff;
            color: #282626;
          }
        }
      }
      .img-container {
        height: 458px;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
    .third-col {
      background-color: #fffef3;
      display: flex;
      flex-direction: column;
      .text-container {
        height: 380px;
        .text {
          text-align: center;
        }
      }
      .img-container {
        height: 409px;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .specialist-section {
    padding: 4rem 0;
    .specialist-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      .text-container {
        gap: 0.5rem;
        .text {
          font-size: 20px;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: 0em;
          text-align: center;

          span {
            font-weight: 500;
          }
        }
        .specialistBtn {
          border: none;
          outline: none;
          width: 183px;
          height: 52px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          font-size: 13px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: center;
          color: #282626;
          background-color: transparent;
          border: 1.5px solid #282626;
          img {
            width: 10px;
            aspect-ratio: 1/1;
          }
          &:hover {
            background-color: #282626;
            color: #ffffff;
          }
        }
      }
      .first-col {
        .text-container {
          height: 247px;
          .text {
            text-align: center;
          }
        }
        .img-container {
          height: 294px;
        }
      }
      .sec-col {
        flex-direction: column-reverse;
        .text-container {
          height: 247px;
          .text {
            text-align: center;
          }
        }
        .img-container {
          height: 294px;
        }
      }
      .third-col {
        .text-container {
          height: 247px;
          .text {
            text-align: center;
          }
        }
        .img-container {
          height: 294px;
        }
      }
    }
  }
}


@media screen and (max-width: 370px){
  .specialist-section {
    .specialist-container {
      
      .text-container {
        .text {
          font-size: 16px;
        }
       
      }
      
    }
  }
}
