@import "../../assets/scss/variable.scss";
@import "../../assets/scss/mixin.scss";

@import "../../assets/scss/mixin.scss";

.footer-section {
  .footer-container {
    padding-top: 8rem;
    width: 85%;
    margin: 0 auto;
    color: #000000;
    a{
      text-decoration: none;
      color: #000000 !important;
    }
    .footer-top-section {
      display: flex;
      align-items: start;
      justify-content: space-between;

      .footer-top-left {
        width: 350px;
        .footer-logo{
          img{
            width: 20%;
          }
        }
        .logo-text {
          padding-top: 1rem;
          word-break: break-word;
          font-size: 16px;
          font-weight: 400;
          line-height: 25px;
          letter-spacing: 0em;
          text-align: left;

          span {
            font-weight: 700;
          }
        }
      }

      .footer-top-right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .footer-top-right-up {
          display: flex;
          align-items: flex-start;
          gap: 150px;
          .footer-top-up {
            .footer-top-up-heading {
              padding-bottom: 1rem;
              text-align: left;
              font-weight: 600;
              font-size: 18px;
              line-height: 29.76px;
            }
            .footer-top-up-heading-text {
              font-size: 16px;
              font-weight: 700;
              line-height: 25px;
              letter-spacing: 0em;
              padding-bottom: 0.8rem;
            }
            .footer-top-up-text {
              font-size: 16px;
              font-weight: 400;
              line-height: 22px;
              letter-spacing: 0em;
              padding-bottom: 0.8rem;
              cursor: pointer;
            }
          }
        }
        .footer-top-right-bottom {
          margin-top: 20px;
          .form {
            label {
              font-size: 16px;
              font-weight: 600;
              line-height: 40px;
              letter-spacing: 0em;
            }
            input {
              border: none;
              outline: none;
              width: 350px;
              height: 45px;
              border: 1px solid #e0e0e0;
              background-color: #e0e0e0;
              // margin-right: 10px;
              padding-left: 10px;
              // font-family: Poppins;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0em;
              text-align: left;
              color: #acacac;
            }
            button {
              border: none;
              outline: none;
              width: 128px;
              height: 45px;
              border: 1px solid #e0e0e0;
              background-color: #e0e0e0;
              margin-left: 10px;
              // font-family: Poppins;
              font-size: 16px;
              font-weight: 400;
              line-height: 22px;
              letter-spacing: 0em;
              color: #2d2d2d;
              cursor: pointer;
              &:hover {
                transition: 0.5s;
                background-color: #d46633;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
    .divider {
      span {
        padding-right: 3rem;
        // color: #ffffff;
        // font-family: Inter;
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
      }
      &::after {
        // background-color: #b5b5b5;
        background-color: #585858;
      }
    }

    .footer-middle-section {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 60px;
      margin-bottom: 80px;
      .content {
        font-size: 14px;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    .link-icon-section {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 1rem;
      img{
       width: 25px;
       aspect-ratio: 1/1;
      }
    }
    .second-divider {
      margin: 4px 0;
    }
    .footer-bottom-section {
      padding-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      .bootom-left {
        display: flex;
        align-items: start;
        gap: 2.5rem;
        div{
          cursor: pointer;
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .footer-mobile-section {
    a{
      text-decoration: none;
      color: #000000 !important;
    }
    .footer-container {
      padding-top: 4rem;
      width: 85%;
      margin: 0 auto;
      color: #000000;
      .footer-top-section {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: space-between;

        .footer-top-left {
          width: 100%;
          .footer-logo{
            img{
              width: 20%;
            }
          }
          .logo-text {
            padding-top: 1rem;
            font-family: Inter;
            font-size: 12px;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: left;

            span {
              font-family: Poppins;
              font-weight: 500;
            }
          }
        }

        .footer-top-right {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .footer-top-right-up {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .footer-top-up {
              .footer-top-up-heading {
                padding-bottom: 1rem;
                font-size: 12px;
                font-weight: 600;
                line-height: 18px;
                letter-spacing: 1.7610000371932983px;
                text-align: left;
              }
              .footer-top-up-heading-text {
                font-size: 12px;
                font-weight: 600;
                line-height: 18px;
                letter-spacing: 1.7610000371932983px;
                text-align: left;

                padding-bottom: 0.8rem;
              }
              .footer-top-up-text {
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0.28200000524520874px;
                text-align: left;

                padding-bottom: 0.8rem;
              }
            }
          }
          .footer-top-right-bottom {
            width: 100%;
            margin-top: 20px;
            .form {
         display: flex;
         flex-direction: column;
         align-items: center;
              label {
                font-family: Inter;
                font-size: 13px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: center;
              }
              input {
                border: none;
                outline: none;
                width: 100%;
                height: 55px;
                border: 1px solid #e0e0e0;
                background-color: #e0e0e0;
                // margin-right: 10px;
                padding-left: 10px;
                // font-family: Poppins;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: center;
                color: #acacac;
              }
              button {
                border: none;
                outline: none;
                width: 100%;
                height: 55px;
                border: 1px solid #e0e0e0;
                background-color: #d46633;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0em;
                color: #fff;
                cursor: pointer;
                margin-top: 1rem;
                &:hover {
                  transition: 0.5s;
                  background-color: #d46633;
                  color: #ffffff;
                }
              }
            }
          }
        }
      }

      .footer-middle-section {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
        .content {
          font-size: 12px;
          font-weight: 400;
          line-height: 25px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
      .second-divider {
        margin: 4px 0;
      }
      .footer-bottom-section {
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0.36000001430511475px;
        text-align: left;

        .bootom-left {
          & > div {
            padding: 1rem 0;
            display: flex;
            align-items: start;
            justify-content: space-between;
            span {
              border-bottom: 1px solid;
            }
          }
        }
        .bootom-right {
          align-self: center;
          text-align: center;
        }
        .link-icon-section {
          padding: 1rem 0;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;
        }
      }
    }
  }
}