@import "../../assets/scss/variable.scss";
.influence-section {
  margin-bottom: 8rem;
  .influence-container {
    width: 75%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-side {
      display: flex;
      flex-direction: column;
      .title-heading {
        
        font-size: 32px;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: 0em;
        color: #251F17;
        margin: 0;
      }
      .title-text {
       
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
        color: #76726E;
        margin: 1rem 0;
      }
      .primaryBtn{
        border: none;
        outline: none;
        width: 191.6px;
        height: 50px;
        background-color: #DE6B32;
        color: #FFFFFF;
font-size: 14px;
font-weight: 600;
line-height: 21px;
letter-spacing: 0em;
text-align: center;


      }
    }
    .right-side {
    }
  }
}
