@import "../../assets/scss/mixin.scss";


.serve-section {
    padding: 5rem 0;
  .serve-container {
    width: 85%;
    margin: 0 auto;
    .title {
        font-size: 26px;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;
      }
      .card-text {
        font-size: 17px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;
      }
    .header-section {
        .heading {
          font-size: 38px;
          font-weight: 500;
          line-height: 57px;
          letter-spacing: 0em;
          text-align: left;
          color: #000000;
        }
        .sub-heading {
          font-size: 19px;
          font-weight: 400;
          line-height: 29px;
          letter-spacing: 0em;
          text-align: left;
          color: #000000;
        }
      }
      .card{
        height: 505px;
        box-shadow: 4.264808177947998px 4.264808177947998px 12.794425010681152px 0px #0C5F9B14;
        border-radius: 20.26px;
      }
      .first-card {
        background-color: #EEF8FF;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 0 4rem;
        gap: 2rem;
        .img-container{
            img{
                width: 100%;
            }
        }
      }
      .second-card {
        background-color: #F9F5F0;
        // box-shadow: 4.264808177947998px 4.264808177947998px 12.794425010681152px 0px #0C5F9B14;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 2.5rem;
        gap: 1rem;
        .img-container{
            img{
                width: 100%;
            }
        }
      }
      .third-card {
        background-color: #FFE5E4;
        // box-shadow: 4.264808177947998px 4.264808177947998px 12.794425010681152px 0px #0C5F9B14;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        padding-top: 3rem;
        .title{
            padding: 0 2.5rem;

        }
        .card-text{
            padding: 0 2.5rem;
        }
        .img-container{
            img{
                width: 100%;

            }
        }
      }
      .fourth-card {
        background-color: #FEEDD5;
        display: flex;
        flex-direction: column;
        padding: 0 4rem;
        .img-container{
            position: relative;
            right: 0;
            height: 300px;
            img{
                height: 100%;
                width: 100%;

            }
        }
      }
      .fifth-card {
        background-color:#FFE1D6;
        // box-shadow: 4.264808177947998px 4.264808177947998px 12.794425010681152px 0px #0C5F9B14;
        display: flex;
        .left{
          width: 80%;
          padding-top: 2.5rem;
          .title{
            padding-left: 2.5rem;

          }
          .card-text{
            padding-left: 2.5rem;
          }
          .img{
            display: flex;
           
            
          }
        }
        .right{
width: 100%;
          img{
            width: 100%;
          }
        }
      }
      .sixth-card {
        background-color: #FEEDD5;
        // box-shadow: 4.264808177947998px 4.264808177947998px 12.794425010681152px 0px #0C5F9B14;
        background-color: #F9F5F0;
        // box-shadow: 4.264808177947998px 4.264808177947998px 12.794425010681152px 0px #0C5F9B14;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 2.5rem;
        .img-container{
            img{
                width: 100%;
            }
        }
      }
  }
}



@include breakpoint(mobile){
  .serve-section {
    padding: 5rem 0;
  .serve-container {
    width: 85%;
    margin: 0 auto;
    .title {
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
       
        color: #000000;
      }
      .card-text {

        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        
        color: #000000;
      }
    .header-section {
        .heading {
          font-size: 20px;
      font-weight: 500;
      line-height: 31px;
      letter-spacing: 0em;
      text-align: center;      
        color: #000000;
        }
        .sub-heading{
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: center;
          color: #000000;
        }
      }
      .card{
        height: 316.24px;
        box-shadow: 4.264808177947998px 4.264808177947998px 12.794425010681152px 0px #0C5F9B14;
        border-radius: 12px;
      }
      .first-card {
        padding: 0 1rem;
        gap: 0.5rem;
      }
      .second-card {
        background-color: #F9F5F0;
        // box-shadow: 4.264808177947998px 4.264808177947998px 12.794425010681152px 0px #0C5F9B14;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 1rem;
        gap: 0.5rem;
        .img-container{
          width: 257.85px;
            img{
                width: 100%;
            }
        }
      }
      .third-card {
        gap: 1rem;
        padding-top: 1rem;
        .title{
            padding: 0 1rem;

        }
        .card-text{
            padding: 0 1rem;
        }
        .img-container{
            img{
                width: 100%;

            }
        }
      }
      .fourth-card {
        background-color: #FEEDD5;
        display: flex;
        flex-direction: column;
        padding: 0 1rem;
        .img-container{
            position: relative;
            right: 0;
            height: 186px;
            img{
                height: 100%;
                width: 100%;

            }
        }
      }



      .fifth-card {
      
        display: flex;
        .left{
          width: 80%;
          padding-top: 1rem;
          .title{
            padding-left: 1rem;

          }
          .card-text{
            padding-left: 1rem;
          }
          .img{
            display: flex;
            position: relative;
            bottom: 0;
            img{
              width: 95.38px;
            }
          }
        }
        .right{
          height: 286px;
          width: 121.44px;
          img{
            height: 100%;
          }
        }
      }





      .sixth-card {
        padding: 0 1rem;
        .img-container{
          width: 194.68px;
            img{
                width: 100%;
            }
        }
      }
  }
}

}