@import "../../assets/scss/mixin.scss";



.have-section{
  padding-bottom: 12.5rem;
  .have-ques-section {
    background-color: #faedaf;
    
    .have-ques-container {
      width: 80%;
      margin: 0 auto;
      padding: 7rem 0;
      display: flex;
      justify-content: space-between;
  
      .left-side {
        width: 22%;
        color: #30312c;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .heading {
          font-size: 36px;
          font-weight: 600;
          line-height: 48px;
          letter-spacing: 0em;
        }
       .bottom{
        display: flex;
        flex-direction: column;
        gap: 2rem;
        .text {
          font-size: 20px;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: 0em;
  
        }
       }
      }
      .right-side {
        width: 70%;
        .ant-collapse{
          background-color: transparent;
          .ant-collapse-item{
            border-bottom: 1px solid #CEC492;
            .ant-collapse-header{
              padding: 1rem 0;
              display: flex;
              align-items: center;
              .col-title{
  font-size: 20px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  color: #251f17;
              }
              .ant-collapse-expand-icon{
                position: relative;
                width: 37.3px;
                height: 37.3px;
                background-color: #ffffff;
                border-radius: 18.66px;
                img{
                  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
                }
              }
            }
            .ant-collapse-content{
              .ant-collapse-content-box{
                padding-left: 0;
                .col-text{
    
    font-size: 16px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    color: #544E47;
    
                }
              }
              
            }
          }
        }
      }
    }
  }
}


@include breakpoint(mobile){
  
.have-section{
  .have-ques-section {
    margin-bottom: 4rem;
    .have-ques-container {
      width: 100%;
      margin: 0 auto;
      padding: 3rem 0;
      .right-side {
        width: 90%;
        margin: 0 auto;
        .ant-collapse{
          background-color: transparent;
          .ant-collapse-item{
            border-bottom: 1px solid #CEC492;
            .ant-collapse-header{
              padding: 1rem 0;
              display: flex;
              align-items: center;
              
              .col-title{
  width: 264px;
                font-size: 16px;
                font-weight: 500;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: left;
                
  color: #251f17;
              }
              .ant-collapse-expand-icon{
                position: relative;
                width: 33px;
                height: 33px;
                background-color: #ffffff;
                border-radius: 50%;
                img{
                  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
                }
              }
            }
            .ant-collapse-content{
              .ant-collapse-content-box{
                padding-left: 0;
                width: 291px;
                .col-text{
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 18px;
                  letter-spacing: 0em;
                  text-align: left;       
    color: #544E47;
    
                }
              }
              
            }
          }
        }
        .heading{
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  
        }
        .last{
  
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  .text{
    padding: 2rem 0;
  }
  
        }
      }
    }
  }
}
}
