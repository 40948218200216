.information-section{
    margin-bottom: 8rem;
    .information-container{
        width: 88%;
        margin: 0 auto;
        background-color: #AA76FF26;
        padding: 6rem 0;
        .heading{

font-size: 40px;
font-weight: 500;
line-height: 68px;
letter-spacing: 0.005em;
text-align: center;
color: #010300;
        }
        .text{
  width: 46%;
  margin: 0 auto;
font-size: 16px;
font-weight: 400;
line-height: 23px;
letter-spacing: 0em;
text-align: center;
color: #010300;
        }
        .form-section{
            width: 50%;
            margin: 1rem auto;
            .form{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .input-section{

                    width: calc(100% - 165px);
                    height: 45px;
                    display: flex;
                    align-items: center;
                    background-color: #FFFFFF;
                    border-radius: 5px;
                    input{
                        width: 50%;
                        background-color: transparent;
                        border: none;
                        outline: none;
                        padding-left: 1.5rem;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0em;
color: #00000040;                        

                    }
                    .name{

                    }
                    .email{
                        border-left: 1px solid #00000040;
                    }
                }
                .button{
                    border: none;
                    outline: none;
                    width: 155px;
                    height: 45px;
                    border: 0.5px solid #CAD6F1;
                    background-color: #1F2020;
                    box-shadow: 0px 25px 50px 0px #16194F0D;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #FFFFFF;
                    cursor: pointer;
                    border-radius: 6px;
                }
            }
          
        }
    }
}